// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XgPafAbKxMjwBFBLWyoQ{margin:0 0 10px 5px;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/AssessmentAndTreatmentsCustomQuestionnaires/CustomQuestionnaires/CustomQuestionaires.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,eAAA","sourcesContent":[".custom_Questionnaire_heading{\n  margin: 0 0 10px 5px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_Questionnaire_heading": `XgPafAbKxMjwBFBLWyoQ`
};
export default ___CSS_LOADER_EXPORT___;
