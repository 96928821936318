import {
  BooleanOperators,
  ChatFlowsEnum,
  DiscussionSteps,
  EdgeTypes,
  IDefaultChatFlowMessages,
  IDialogueDashboard,
  KeyType,
  LeftHandContextType,
  NodeTypes,
  PromptType,
  RightHandContextType,
  TextOperators
} from "@limbic/types"

export type DefaultDialogues =
  | DiscussionSteps.Intro
  | DiscussionSteps.Eligibility
  | DiscussionSteps.SelfReferral
  | DiscussionSteps.GetName
  | DiscussionSteps.Goodbye
  | DiscussionSteps.Crisis
  | DiscussionSteps.Permissions
  | DiscussionSteps.Assessment
  | DiscussionSteps.BookAppointment
  | "notImplemented"

// TODO: This type needs to be cleaned up so we won't need to
// add each current message as an empty string
const CURRENT_MESSAGES: Record<keyof IDefaultChatFlowMessages, string> = {
  askPhoneNumber: "",
  askCanIContactYouOnPhoneNumber: "",
  askDoYouWantToShareEmail: "",
  askEmail: "",
  askEmailPermission: "",
  askLongTermMedicalCondition: "",
  askLongTermMedicalConditionOther: "",
  askSexuality: "",
  askPreferredContactMethod: "",
  askNHSNumber: "",
  askMainIssue: "",
  closingMessage: "",
  askDisabilityStatus: "",
  askDisability: "",
  askDisabilityOther: "",
  askPrimaryLanguage: "",
  askRequiresAnInterpreter: "",
  askInterpreterLanguage: "",
  askAbleToCommunicateInEnglish: "",
  askGender: "",
  askSameGenderAsBirth: "",
  askHasADHD: "",
  askHasASD: "",
  askWhatIsYourGoal: "",
  askUSAddress: "",
  askPermissionToMail: "",
  askPriorMHTreatment: "",
  sayReferralFailed: "",
  sayReferralSucceeded: "",
  askReligion: "",
  askPostCodeOfUser: "",
  askWantMeToReferYouAnyway: "",
  askWantMeToReferYouAnywaySpineSearch: "",
  askYourAreaOrGPPostCode: "",
  askPostCodeOfUserSpine: "",
  sayIntroToSpineSearch: "",
  saySearchSuccessful: "",
  sayICouldntFindYourGP: "",
  sayCannotReferYou: "",
  askAlcohol: "",
  askAlcoholFrequency: "",
  askAlcoholQuantity: "",
  askAlcoholImpactsLife: "",
  askSubstances: "",
  askSubstancesForMood: "",
  askSubstancesInfo: "",
  askSubstancesImpactLife: "",
  askNonPrescribedSubstances: "",
  askSubstancesAreMedication: "",
  askPrescribedMedication: "",
  askMedicationInfo: "",
  askMedicationWithinDosage: "",
  askMedicationNotTaking: "",
  askMedicationNotTakingInfo: "",
  askBirthday: "",
  askFullName: "",
  sayPleaseGiveFullName: "",
  sayNiceToMeetYou: "",
  askIsPreferredName: "",
  sayPleaseGivePreferredName: "",
  sayTriggerWordsDetected: "",
  askAreYouInCrisis: "",
  saySorryForTheMisunderstanding: "",
  saySorryToHear: "",
  sayCrisisNumbers: "",
  recapMessage: "",
  showTOSLinks: "",
  askResearchConsent: "",
  sayReady: "",
  askCanYouKeepYourselfSafe: "",
  sayCrisisRiskPathway: "",
  sayCrisisNumbersRiskPathway: "",
  sayLetsCarryOn: "",
  conditionalRecapMessage0: "",
  conditionalRecapMessage1: "",
  conditionalRecapMessage2: "",
  conditionalRecapMessage3: "",
  conditionalRecapMessage4: "",
  conditionalRecapMessage5: "",
  conditionalRecapMessage6: "",
  conditionalRecapMessage7: "",
  conditionalRecapMessage8: "",
  conditionalRecapMessage9: "",
  conditionalRecapMessage10: "",
  conditionalRecapMessage11: "",
  conditionalRecapMessage12: "",
  conditionalRecapMessage13: "",
  conditionalRecapMessage14: "",
  conditionalRecapMessage15: "",
  conditionalRecapMessage16: "",
  conditionalRecapMessage17: "",
  conditionalRecapMessage18: "",
  conditionalRecapMessage19: "",
  askFeedback: "",
  saySorryToHearThat: "",
  sayUnderstood: "",
  sayImGlad: "",
  sayBookAppointmentIntro: "",
  sayBookAppointmentSubmitReferralFailed: "",
  sayBookAppointmentGetSlotsFailed: "",
  askBookAppointmentSelectSlot: "",
  sayBookAppointmentConfirmSlot: "",
  sayBookAppointmentSlotUnavailable: "",
  sayBookAppointmentBookingError: "",
  sayBookAppointmentBookedSuccessfully: "",
  sayBookAppointmentGoodbyeLeave: "",
  smiIntroMessage: ""
}

export type DefaultFlows = Record<DefaultDialogues, Record<string, IDialogueDashboard>>

const defaultFlows: DefaultFlows = {
  notImplemented: {
    Main: {
      nodes: [
        {
          id: "5",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 0,
            y: -100
          },
          width: 28,
          height: 28
        },
        {
          id: "6",
          type: NodeTypes.Question,
          data: {
            label: "Double click to edit this question"
          },
          position: {
            x: 0,
            y: 0
          },
          width: 234,
          height: 55
        }
      ],
      edges: [
        {
          source: "5",
          target: "6",
          sourceHandle: null,
          targetHandle: "t-top",
          id: "reactflow__edge-5-6t-top"
        }
      ]
    }
  },
  [DiscussionSteps.Intro]: {
    Main: {
      nodes: [
        {
          id: "1",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 5,
            y: -420
          },
          selected: false,
          positionAbsolute: {
            x: 5,
            y: -420
          },
          dragging: false
        },
        {
          id: "7",
          type: NodeTypes.Question,
          data: {
            label: [
              "Hi there!",
              "I'm Limbic",
              "I'm a friendly robot assistant to help you access psychological support"
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "7",
            type: "message"
          },
          position: {
            x: 75,
            y: -325
          },
          width: 240,
          height: 150,
          selected: false,
          positionAbsolute: {
            x: 75,
            y: -325
          },
          dragging: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: [
              "Hi there!",
              "I'm Limbic",
              "I'm a friendly robot assistant to help you access psychological support"
            ],
            questionName: "hello",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "hello"
          }
        },
        {
          id: "6",
          type: NodeTypes.Condition,
          data: {
            label: "Is it a direct referral?",
            nodeId: "6"
          },
          position: {
            x: -165,
            y: 95
          },
          width: 100,
          height: 100,
          selected: false,
          positionAbsolute: {
            x: -165,
            y: 95
          },
          dragging: false,
          settings: {
            ifStatement: {
              input: "",
              action: "isDirectReferral"
            },
            thenBlock: {
              nextStep: ""
            },
            elseBlock: {
              nextStep: ""
            }
          }
        },
        {
          id: "2",
          type: NodeTypes.Question,
          data: {
            label: ["Can I help?"],
            choices: [
              {
                body: "Yes, I'd like to refer myself to {organisationName}",
                value: "Yes, I'd like to refer myself to {organisationName}"
              },
              {
                body: "No, I'm just browsing",
                value: "No, I'm just browsing"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "2",
            type: "inlinePicker"
          },
          position: {
            x: 70,
            y: 80
          },
          width: 240,
          height: 130,
          selected: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: ["Can I help?"],
            questionName: "can i help",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: [
                "Yes, I'd like to refer myself to {organisationName}",
                "No, I'm just browsing"
              ],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "canIHelp"
          },
          positionAbsolute: {
            x: 70,
            y: 80
          },
          dragging: false
        },
        {
          id: "4",
          type: NodeTypes.Question,
          data: {
            label: ['No problem. If you change your mind, just type "Hello Limbic" to wake me up'],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "4",
            type: KeyType.Text
          },
          position: {
            x: 305,
            y: 320
          },
          width: 240,
          height: 112,
          selected: false,
          positionAbsolute: {
            x: 305,
            y: 320
          },
          dragging: false,
          settings: {
            promptType: PromptType.Text,
            currentMessage: "",
            messages: [
              'No problem. If you change your mind, just type "Hello Limbic" to wake me up'
            ],
            questionName: "say if you change your mind",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "sayIfYouChangeYourMind"
          }
        },
        {
          id: "5",
          type: NodeTypes.Question,
          data: {
            label: ["Hello again!", "Can I help?"],
            choices: [
              {
                body: "Yes, I'd like to refer myself to {organisationName}",
                value: "Yes, I'd like to refer myself to {organisationName}"
              },
              {
                body: "No, I'm just browsing",
                value: "No, I'm just browsing"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "5",
            type: "inlinePicker"
          },
          position: {
            x: 365,
            y: 630
          },
          width: 240,
          height: 163,
          selected: false,
          positionAbsolute: {
            x: 365,
            y: 630
          },
          dragging: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: ["Hello again!", "Can I help?"],
            questionName: "ask can i help",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: [
                "Yes, I'd like to refer myself to {organisationName}",
                "No, I'm just browsing"
              ],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "askCanIHelp"
          }
        },
        {
          id: "3",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 110,
            y: 805
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 110,
            y: 805
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "1",
          sourceHandle: null,
          target: "7",
          targetHandle: "t-top",
          id: "reactflow__edge-1-7t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "7",
          sourceHandle: "s-bottom",
          target: "6",
          targetHandle: "t-top",
          id: "reactflow__edge-7s-bottom-6t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "6",
          sourceHandle: "s-right",
          target: "2",
          targetHandle: "t-left",
          id: "reactflow__edge-6s-right-2t-left"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "2",
          sourceHandle: "s-bottom",
          target: "4",
          targetHandle: "t-left",
          id: "reactflow__edge-22-optionHandle-No, I'm just browsing-1-4t-left",
          data: {
            label: "No, I'm just browsing"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "4",
          sourceHandle: "s-bottom",
          target: "5",
          targetHandle: "t-top",
          id: "reactflow__edge-4s-bottom-5t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "5",
          sourceHandle: "s-bottom",
          target: "4",
          targetHandle: "t-right",
          id: "reactflow__edge-55-optionHandle-No, I'm just browsing-1-4t-right",
          data: {
            label: "No, I'm just browsing"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "6",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-top",
          id: "reactflow__edge-6s-bottom-3t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "5",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-right",
          id: "reactflow__edge-55-optionHandle-Yes, I'd like to refer myself to {organisationName}-0-3t-right",
          data: {
            label: "Yes, I'd like to refer myself to {organisationName}"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "2",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-top",
          id: "reactflow__edge-22-optionHandle-Yes, I'd like to refer myself to {organisationName}-0-3t-top",
          data: {
            label: "Yes, I'd like to refer myself to {organisationName}"
          }
        }
      ]
    }
  },
  [DiscussionSteps.Permissions]: {
    Main: {
      nodes: [
        {
          id: "1",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 0,
            y: -100
          },
          width: 28,
          height: 28
        },
        {
          id: "2",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Display privacy policy and terms and conditions and get user's consent",
            chatFlow: ChatFlowsEnum.GET_PERMISSIONS,
            nodeId: "2"
          },
          position: {
            x: -90,
            y: 10
          },
          width: 240,
          height: 56,
          selected: true,
          positionAbsolute: {
            x: -90,
            y: 10
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.GET_PERMISSIONS,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                showTOSLinks: [
                  "To get you the best referral, I will need to share your answers with {organisationName}",
                  "I want to reassure you that your details will be stored safely and kept confidential",
                  "So..."
                ],
                askResearchConsent: [
                  "I am also working with researchers to improve mental health treatment",
                  "Are you ok if Limbic uses your data anonymously to support the development of the product and research, which might be used for scientific publications?",
                  "Your answer will not impact the decision on whether or not we can provide you with this service"
                ],
                sayReady: ["Let's continue", "Ready?"]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              }
            }
          }
        },
        {
          id: "3",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 20,
            y: 150
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 20,
            y: 150
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "1",
          sourceHandle: null,
          target: "2",
          targetHandle: "t-top",
          id: "reactflow__edge-1-2t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "2",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-top",
          id: "reactflow__edge-2s-bottom-3t-top"
        }
      ]
    }
  },
  [DiscussionSteps.Eligibility]: {
    Main: {
      nodes: [
        {
          id: "5",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 20,
            y: -255
          },
          width: 28,
          height: 28,
          selected: false,
          positionAbsolute: {
            x: 20,
            y: -255
          },
          dragging: false
        },
        {
          id: "18",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Ask the user's date of birth",
            chatFlow: ChatFlowsEnum.COLLECT_DATE_OF_BIRTH,
            nodeId: "18"
          },
          position: {
            x: -555,
            y: -155
          },
          width: 172,
          height: 99,
          selected: false,
          positionAbsolute: {
            x: -555,
            y: -155
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.COLLECT_DATE_OF_BIRTH,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {},
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              }
            }
          }
        },
        {
          id: "6",
          type: NodeTypes.Question,
          data: {
            label: [
              "Do you currently feel at risk of harming yourself, or can't keep yourself safe?"
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "6",
            type: "inlinePicker"
          },
          position: {
            x: -110,
            y: 20
          },
          width: 240,
          height: 114,
          selected: false,
          dragging: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: [
              "Do you currently feel at risk of harming yourself, or can't keep yourself safe?"
            ],
            questionName: "urgent support",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "urgentSupport"
          },
          positionAbsolute: {
            x: -110,
            y: 20
          }
        },
        {
          id: "7",
          type: NodeTypes.Action,
          data: {
            label: "Set state value",
            nodeId: "7"
          },
          position: {
            x: -190,
            y: 250
          },
          width: 100,
          height: 100,
          selected: false,
          positionAbsolute: {
            x: -190,
            y: 250
          },
          dragging: false,
          settings: {
            action: "setState",
            actionStateKey: "requiresUrgentSupport",
            actionStateValueType: "boolean",
            actionStateValue: "true"
          }
        },
        {
          id: "17",
          type: NodeTypes.Question,
          data: {
            label: [
              "Sorry to hear that {name}",
              "However {organisationName} does not provide urgent care",
              "If you need urgent support, please dial NHS 111 and select Option 2",
              "In a medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
              "Other helplines available:\nYou can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\nIf you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help",
              "You can refer yourself back to {organisationName} when you are no longer in crisis"
            ],
            choices: [
              {
                body: "Okay",
                value: "Okay"
              },
              {
                body: "I understand",
                value: "I understand"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "17",
            type: "inlinePicker"
          },
          position: {
            x: -380,
            y: 420
          },
          width: 240,
          height: 538,
          selected: false,
          positionAbsolute: {
            x: -380,
            y: 420
          },
          dragging: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: [
              "Sorry to hear that {name}",
              "However {organisationName} does not provide urgent care",
              "If you need urgent support, please dial NHS 111 and select Option 2",
              "In a medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
              "Other helplines available:\nYou can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\nIf you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help",
              "You can refer yourself back to {organisationName} when you are no longer in crisis"
            ],
            questionName: "say crisis",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Okay", "I understand"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "sayCrisis"
          }
        },
        {
          id: "9",
          type: NodeTypes.Action,
          data: {
            label: "Go to a new dialogue",
            nodeId: "9"
          },
          position: {
            x: -320,
            y: 1070
          },
          width: 100,
          height: 100,
          selected: false,
          positionAbsolute: {
            x: -320,
            y: 1070
          },
          dragging: false,
          settings: {
            action: "nextDialogue",
            actionStateKey: "",
            actionStateValueType: "boolean",
            actionStateValue: "",
            nextDialogue: DiscussionSteps.Goodbye
          }
        },
        {
          id: "8",
          type: NodeTypes.Action,
          data: {
            label: "Set state value",
            nodeId: "8"
          },
          position: {
            x: 105,
            y: 205
          },
          width: 100,
          height: 100,
          selected: false,
          positionAbsolute: {
            x: 105,
            y: 205
          },
          dragging: false,
          settings: {
            action: "setState",
            actionStateKey: "requiresUrgentSupport",
            actionStateValueType: "boolean",
            actionStateValue: "false"
          }
        },
        {
          id: "10",
          type: NodeTypes.Question,
          data: {
            label: ["Have you ever served in the British Armed Forces?"],
            choices: [
              {
                body: "Yes - ex-services",
                value: "Yes - ex-services"
              },
              {
                body: "Yes - currently serving (including reservists)",
                value: "Yes - currently serving (including reservists)"
              },
              {
                body: "No",
                value: "No"
              },
              {
                body: "Dependant of a ex-serving member",
                value: "Dependant of a ex-serving member"
              },
              {
                body: "Not Stated",
                value: "Not Stated"
              },
              {
                body: "Unknown",
                value: "Unknown"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "10",
            type: "inlinePicker"
          },
          position: {
            x: 35,
            y: 400
          },
          width: 240,
          height: 235,
          selected: false,
          positionAbsolute: {
            x: 35,
            y: 400
          },
          dragging: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: ["Have you ever served in the British Armed Forces?"],
            questionName: "armed forces",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: [
                "Yes - ex-services",
                "Yes - currently serving (including reservists)",
                "No",
                "Dependant of a ex-serving member",
                "Not Stated",
                "Unknown"
              ],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "armedForces"
          }
        },
        {
          id: "11",
          type: NodeTypes.Question,
          data: {
            label: [
              "So {name}, to get you the more appropriate support we suggest you access specialist support through your current Armed Forces service",
              "Or you can contact your local GP",
              "If you feel there is a risk of harming yourself or others please contact or the Mental Health Matters Helpline on 0800 001 4331 or the Samaritans on 116 123 to talk about how you are feeling",
              "Always contact 999 in the case of a physical emergency",
              "Thank you for contacting {organisationName}"
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "11",
            type: "message"
          },
          position: {
            x: 295,
            y: 715
          },
          width: 240,
          height: 346,
          selected: false,
          dragging: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: [
              "So {name}, to get you the more appropriate support we suggest you access specialist support through your current Armed Forces service",
              "Or you can contact your local GP",
              "If you feel there is a risk of harming yourself or others please contact or the Mental Health Matters Helpline on 0800 001 4331 or the Samaritans on 116 123 to talk about how you are feeling",
              "Always contact 999 in the case of a physical emergency",
              "Thank you for contacting {organisationName}"
            ],
            questionName: "armed forces signpost",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "armedForcesSignpost"
          },
          positionAbsolute: {
            x: 295,
            y: 715
          }
        },
        {
          id: "12",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Check user's eligibility using the NHS official database",
            chatFlow: ChatFlowsEnum.SPINE_SEARCH,
            nodeId: "12"
          },
          position: {
            x: -55,
            y: 735
          },
          width: 240,
          height: 116,
          selected: false,
          settings: {
            chatFlow: ChatFlowsEnum.SPINE_SEARCH,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                askPostCodeOfUserSpine: ["Please type your postcode below"],
                sayIntroToSpineSearch: [
                  "Alright, I'm just going to search you in the NHS database with the details you've given me"
                ],
                saySearchSuccessful: ["Found you!"],
                sayICouldntFindYourGP: [
                  "Found you!",
                  "Hmm, however it looks like I wasn't able to find your GP",
                  "Let me see if I can find your GP in an other way"
                ]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              }
            }
          },
          positionAbsolute: {
            x: -55,
            y: 735
          },
          dragging: false
        },
        {
          id: "14",
          type: NodeTypes.Action,
          data: {
            label: "Go to a new dialogue",
            nodeId: "14"
          },
          position: {
            x: -145,
            y: 1045
          },
          width: 100,
          height: 100,
          selected: true,
          dragging: false,
          positionAbsolute: {
            x: -145,
            y: 1045
          },
          settings: {
            action: "nextDialogue",
            actionStateKey: "",
            actionStateValueType: "boolean",
            actionStateValue: "",
            nextDialogue: DiscussionSteps.SuitableServicesSignpost
          }
        },
        {
          id: "15",
          type: NodeTypes.Action,
          data: {
            label: "Go to a new dialogue",
            nodeId: "15"
          },
          position: {
            x: 180,
            y: 900
          },
          width: 100,
          height: 100,
          selected: false,
          positionAbsolute: {
            x: 180,
            y: 900
          },
          dragging: false,
          settings: {
            action: "nextDialogue",
            actionStateKey: "",
            actionStateValueType: "boolean",
            actionStateValue: "",
            nextDialogue: DiscussionSteps.UnderageSignpost
          }
        },
        {
          id: "19",
          type: NodeTypes.Action,
          data: {
            label: "Go to a new dialogue",
            nodeId: "19"
          },
          position: {
            x: -740,
            y: 1075
          },
          width: 100,
          height: 100,
          selected: false,
          dragging: false,
          settings: {
            action: "nextDialogue",
            actionStateKey: "",
            actionStateValueType: "boolean",
            actionStateValue: "",
            nextDialogue: DiscussionSteps.UnderageSignpost
          },
          positionAbsolute: {
            x: -740,
            y: 1075
          }
        },
        {
          id: "16",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 60,
            y: 1675
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 60,
            y: 1675
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "5",
          sourceHandle: null,
          target: "18",
          targetHandle: "t-top",
          id: "reactflow__edge-5-18t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "18",
          sourceHandle: "s-bottom",
          target: "6",
          targetHandle: "t-top",
          id: "reactflow__edge-1818-optionHandle-eligible-0-6t-top",
          data: {
            label: "eligible"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "6",
          sourceHandle: "s-bottom",
          target: "7",
          targetHandle: "t-top",
          id: "reactflow__edge-66-optionHandle-Yes-0-7t-top",
          data: {
            label: "Yes"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "7",
          sourceHandle: "s-bottom",
          target: "17",
          targetHandle: "t-top",
          id: "reactflow__edge-7s-bottom-17t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "17",
          sourceHandle: "s-bottom",
          target: "9",
          targetHandle: "t-top",
          id: "reactflow__edge-17s-bottom-9t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "6",
          sourceHandle: "s-bottom",
          target: "8",
          targetHandle: "t-top",
          id: "reactflow__edge-66-optionHandle-No-1-8t-top",
          data: {
            label: "No"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "8",
          sourceHandle: "s-bottom",
          target: "10",
          targetHandle: "t-top",
          id: "reactflow__edge-8s-bottom-10t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "10",
          sourceHandle: "s-bottom",
          target: "11",
          targetHandle: "t-top",
          id: "reactflow__edge-1010-optionHandle-Yes - currently serving (including reservists)-1-11t-top",
          data: {
            label: "Yes - currently serving (including reservists)"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "10",
          sourceHandle: "s-bottom",
          target: "12",
          targetHandle: "t-top",
          id: "reactflow__edge-10s-bottom-12t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "12",
          sourceHandle: "s-bottom",
          target: "14",
          targetHandle: "t-top",
          id: "reactflow__edge-1212-optionHandle-ineligible-1-14t-top",
          data: {
            label: "ineligible"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "12",
          sourceHandle: "s-bottom",
          target: "15",
          targetHandle: "t-top",
          id: "reactflow__edge-1212-optionHandle-underage-2-15t-top",
          data: {
            label: "underage"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "18",
          sourceHandle: "s-bottom",
          target: "19",
          targetHandle: "t-top",
          id: "reactflow__edge-1818-optionHandle-underage-1-19t-top",
          data: {
            label: "underage"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "9",
          sourceHandle: "s-bottom",
          target: "16",
          targetHandle: "t-top",
          id: "reactflow__edge-9s-bottom-16t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "11",
          sourceHandle: "s-bottom",
          target: "16",
          targetHandle: "t-top",
          id: "reactflow__edge-11s-bottom-16t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "14",
          sourceHandle: "s-bottom",
          target: "16",
          targetHandle: "t-top",
          id: "reactflow__edge-14s-bottom-16t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "15",
          sourceHandle: "s-bottom",
          target: "16",
          targetHandle: "t-top",
          id: "reactflow__edge-15s-bottom-16t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "12",
          sourceHandle: "s-bottom",
          target: "16",
          targetHandle: "t-top",
          id: "reactflow__edge-1212-optionHandle-eligible-0-16t-top",
          data: {
            label: "eligible"
          }
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "19",
          sourceHandle: "s-bottom",
          target: "16",
          targetHandle: "t-top",
          id: "reactflow__edge-19s-bottom-16t-top"
        }
      ]
    }
  },
  [DiscussionSteps.SelfReferral]: {
    Main: {
      nodes: [
        {
          id: "19",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 105,
            y: 880
          },
          width: 28,
          height: 28,
          selected: false,
          positionAbsolute: {
            x: 105,
            y: 880
          },
          dragging: false
        },
        {
          id: "21",
          type: NodeTypes.Question,
          data: {
            label: [
              "So {name}. please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you) "
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            nodeId: "21",
            type: KeyType.Text
          },
          position: {
            x: 55,
            y: 1560
          },
          width: 240,
          height: 155,
          positionAbsolute: {
            x: 55,
            y: 1560
          },
          dragging: false,
          settings: {
            promptType: PromptType.Text,
            currentMessage: "",
            messages: [
              "So {name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you) "
            ],
            questionName: "main issue",
            promptSettings: {
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false
            },
            questionID: "mainIssue"
          }
        },
        {
          id: "22",
          type: NodeTypes.Question,
          data: {
            label: [
              "Thank you for sharing {name}, you've come to the right place ",
              "And can you tell me what you're hoping to get out of therapy? "
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            nodeId: "22",
            type: KeyType.Text
          },
          position: {
            x: 0,
            y: 1780
          },
          width: 240,
          height: 145,
          positionAbsolute: {
            x: 0,
            y: 1780
          },
          dragging: false,
          settings: {
            promptType: PromptType.Text,
            currentMessage: "",
            messages: [
              "Thank you for sharing {name}, you've come to the right place ",
              "And can you tell me what you're hoping to get out of therapy? "
            ],
            questionName: "therapy expectations",
            promptSettings: {
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false
            },
            questionID: "therapyExpectations"
          }
        },
        {
          id: "30",
          type: NodeTypes.Question,
          data: {
            label: [
              "Wonderful!",
              "I'm now going to add you to the service as a new referral",
              "There are just a few more details I need from you"
            ],
            choices: [
              {
                body: "Let's do it",
                value: "Let's do it"
              },
              {
                body: "Okay",
                value: "Okay"
              }
            ],
            nodeId: "30",
            type: "inlinePicker"
          },
          position: {
            x: 140,
            y: 980
          },
          width: 240,
          height: 181,
          positionAbsolute: {
            x: 140,
            y: 980
          },
          dragging: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: [
              "Wonderful!",
              "I'm now going to add you to the service as a new referral",
              "There are just a few more details I need from you"
            ],
            questionName: "say intro",
            promptSettings: {
              options: ["Let's do it", "Okay"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false
            },
            questionID: "sayIntro"
          }
        },
        {
          id: "40",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Check user postcode and ask address",
            nodeId: "40"
          },
          position: {
            x: 115,
            y: 1440
          },
          width: 233,
          height: 39,
          selected: false,
          positionAbsolute: {
            x: 115,
            y: 1440
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP,
            chatFlowSettings: {
              checkPostCodeFromAddressLookup: {
                skipPermissionsQuestion: false,
                hideEarlierYouSaid: false,
                startWithAskPostcode: false
              }
            }
          }
        },
        {
          id: "41",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 240,
            y: 2090
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 240,
            y: 2090
          },
          dragging: false
        },
        {
          id: "42",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Submit referral to service",
            chatFlow: ChatFlowsEnum.SUBMIT_REFERRAL,
            nodeId: "42"
          },
          position: {
            x: 190,
            y: 1985
          },
          width: 164,
          height: 39,
          selected: false,
          settings: {
            chatFlow: ChatFlowsEnum.SUBMIT_REFERRAL,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                sayReferralSucceeded: [
                  "And that's everything",
                  "You've officially been referred to {iaptName}",
                  "Congratulations on taking this important step towards better mental health!"
                ],
                sayReferralFailed: [
                  "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data to {iaptName}",
                  "I've notified my creators of this issue",
                  "If you don't wish to wait, you can manually refer yourself by following this link [here]{url})"
                ]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              submitReferral: {
                payloadMap: {
                  doYouHaveEmail: {},
                  emailAddress: {},
                  mainIssue: {},
                  phoneNumber: {},
                  retryPhoneNumber: {},
                  sayIntro: {},
                  therapyExpectations: {},
                  voicemailPermission: {},
                  voicemailTextPermission: {}
                }
              }
            }
          },
          positionAbsolute: {
            x: 190,
            y: 1985
          },
          dragging: false
        },
        {
          id: "43",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Collect phone number and permissions",
            chatFlow: ChatFlowsEnum.COLLECT_PHONE_NUMBER,
            nodeId: "43"
          },
          position: {
            x: 165,
            y: 1245
          },
          width: 240,
          height: 56,
          selected: true,
          settings: {
            chatFlow: ChatFlowsEnum.COLLECT_PHONE_NUMBER,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                askPhoneNumber: ["What's the best phone number to reach you on?"],
                askCanIContactYouOnPhoneNumber: [
                  "And is it ok to send you text messages or leave a voicemail if I can't get through?"
                ]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              },
              assessmentAndTreatments: {
                enableADSM: true,
                shouldSendRiskEmail: false
              },
              collectFeedback: {
                shouldAskAdditionalFeedback: true
              },
              collectPreferredCorrespondence: {
                shouldDisplayTextMessageOption: true
              },
              spineSearch: {
                canReferWithoutGP: false,
                shouldUseServiceSearchFallback: true
              },
              bookAppointment: {}
            }
          },
          positionAbsolute: {
            x: 165,
            y: 1245
          },
          dragging: false
        },
        {
          id: "44",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Collect email",
            chatFlow: ChatFlowsEnum.COLLECT_EMAIL,
            nodeId: "44"
          },
          position: {
            x: 205,
            y: 1360
          },
          width: 95,
          height: 39,
          selected: false,
          positionAbsolute: {
            x: 205,
            y: 1360
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.COLLECT_EMAIL,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                askDoYouWantToShareEmail: ["Do you have an email address?"],
                askEmail: ["Please type your email address"],
                askEmailPermission: ["Do you allow us to contact you over email?"]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              },
              assessmentAndTreatments: {
                enableADSM: true,
                shouldSendRiskEmail: false
              },
              collectFeedback: {
                shouldAskAdditionalFeedback: true
              },
              collectPreferredCorrespondence: {
                shouldDisplayTextMessageOption: true
              },
              spineSearch: {
                canReferWithoutGP: false,
                shouldUseServiceSearchFallback: true
              },
              bookAppointment: {},
              collectEmail: {
                hasPreliminaryQuestion: true,
                shouldAskEmailPermission: true
              }
            }
          }
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "21",
          sourceHandle: "s-bottom",
          target: "22",
          targetHandle: "t-top",
          id: "reactflow__edge-21s-bottom-22t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "19",
          sourceHandle: "s-bottom",
          target: "30",
          targetHandle: "t-top",
          id: "reactflow__edge-19-30t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "42",
          sourceHandle: "s-bottom",
          target: "41",
          targetHandle: "t-top",
          id: "reactflow__edge-42s-bottom-41t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "22",
          sourceHandle: "s-bottom",
          target: "42",
          targetHandle: "t-top",
          id: "reactflow__edge-22s-bottom-42t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "40",
          sourceHandle: "s-bottom",
          target: "21",
          targetHandle: "t-top",
          id: "reactflow__edge-40s-bottom-21t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "30",
          sourceHandle: "s-bottom",
          target: "43",
          targetHandle: "t-top",
          id: "reactflow__edge-30s-bottom-43t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "43",
          sourceHandle: "s-bottom",
          target: "44",
          targetHandle: "t-top",
          id: "reactflow__edge-43s-bottom-44t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "44",
          sourceHandle: "s-bottom",
          target: "40",
          targetHandle: "t-top",
          id: "reactflow__edge-44s-bottom-40t-top"
        }
      ]
    }
  },
  [DiscussionSteps.GetName]: {
    Main: {
      nodes: [
        {
          id: "1",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 0,
            y: -100
          },
          width: 28,
          height: 28
        },
        {
          id: "6",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Ask user's name and preferred name",
            chatFlow: ChatFlowsEnum.COLLECT_NAME,
            nodeId: "6"
          },
          position: {
            x: -90,
            y: -15
          },
          width: 227,
          height: 39,
          selected: true,
          positionAbsolute: {
            x: -90,
            y: -15
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.COLLECT_NAME,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                askFullName: ["What's your full name? ✏️"],
                sayPleaseGiveFullName: ["Please enter your full name"],
                sayNiceToMeetYou: ["Nice to meet you {name}!"],
                askIsPreferredName: ["Is {name} your preferred first name?"],
                sayPleaseGivePreferredName: ["What would you like to be called?"]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              }
            }
          }
        },
        {
          id: "3",
          type: NodeTypes.Question,
          data: {
            label: ["And how would you like to be addressed?"],
            choices: [
              {
                body: "Mr",
                value: "Mr"
              },
              {
                body: "Mrs",
                value: "Mrs"
              },
              {
                body: "Miss",
                value: "Miss"
              },
              {
                body: "Ms",
                value: "Ms"
              },
              {
                body: "Dr",
                value: "Dr"
              },
              {
                body: "Rev",
                value: "Rev"
              },
              {
                body: "Prof",
                value: "Prof"
              },
              {
                body: "Mx",
                value: "Mx"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "3",
            type: "inlinePicker"
          },
          position: {
            x: -155,
            y: 100
          },
          width: 240,
          height: 130,
          selected: false,
          positionAbsolute: {
            x: -155,
            y: 100
          },
          dragging: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: ["And how would you like to be addressed?"],
            questionName: "how would you like to be addressed",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Mr", "Mrs", "Miss", "Ms", "Dr", "Rev", "Prof", "Mx"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "howWouldYouLikeToBeAddressed"
          }
        },
        {
          id: "4",
          type: NodeTypes.Question,
          data: {
            label: ["And what are your preferred pronouns?"],
            choices: [
              {
                body: "She/Her/Her",
                value: "She/Her/Her"
              },
              {
                body: "He/Him/His",
                value: "He/Him/His"
              },
              {
                body: "They/Them/Their",
                value: "They/Them/Their"
              },
              {
                body: "Ae/Aer/Aer",
                value: "Ae/Aer/Aer"
              },
              {
                body: "Ey/Em/Eir",
                value: "Ey/Em/Eir"
              },
              {
                body: "Fae/Faer/Faer",
                value: "Fae/Faer/Faer"
              },
              {
                body: "Per/Per/Pers",
                value: "Per/Per/Pers"
              },
              {
                body: "Ve/Ver/Vers",
                value: "Ve/Ver/Vers"
              },
              {
                body: "Xe/Xem/Xyr",
                value: "Xe/Xem/Xyr"
              },
              {
                body: "Ze/Hir/Hir",
                value: "Ze/Hir/Hir"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "4",
            type: "inlinePicker"
          },
          position: {
            x: -60,
            y: 300
          },
          width: 240,
          height: 221,
          selected: false,
          dragging: false,
          settings: {
            promptType: PromptType.InlinePicker,
            currentMessage: "",
            messages: ["And what are your preferred pronouns?"],
            questionName: "ask pronoun",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: [
                "She/Her/Her",
                "He/Him/His",
                "They/Them/Their",
                "Ae/Aer/Aer",
                "Ey/Em/Eir",
                "Fae/Faer/Faer",
                "Per/Per/Pers",
                "Ve/Ver/Vers",
                "Xe/Xem/Xyr",
                "Ze/Hir/Hir"
              ],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: false,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "askPronoun"
          },
          positionAbsolute: {
            x: -60,
            y: 300
          }
        },
        {
          id: "5",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 20,
            y: 590
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 20,
            y: 590
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "1",
          sourceHandle: null,
          target: "6",
          targetHandle: "t-top",
          id: "reactflow__edge-1-6t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "6",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-top",
          id: "reactflow__edge-6s-bottom-3t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "3",
          sourceHandle: "s-bottom",
          target: "4",
          targetHandle: "t-top",
          id: "reactflow__edge-3s-bottom-4t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "4",
          sourceHandle: "s-bottom",
          target: "5",
          targetHandle: "t-top",
          id: "reactflow__edge-4s-bottom-5t-top"
        }
      ]
    }
  },
  [DiscussionSteps.Goodbye]: {
    "Use goodbye recap preset flow": {
      nodes: [
        {
          id: "1",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 10,
            y: -210
          },
          selected: false,
          positionAbsolute: {
            x: 10,
            y: -210
          },
          dragging: false,
          width: 28,
          height: 28
        },
        {
          id: "4",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Goodbye Recap: Summarise outcomes from the referral and next steps",
            chatFlow: ChatFlowsEnum.GOODBYE_RECAP,
            nodeId: "4"
          },
          position: {
            x: -50,
            y: -100
          },
          width: 240,
          height: 56,
          selected: true,
          positionAbsolute: {
            x: -50,
            y: -100
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.GOODBYE_RECAP,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                conditionalRecapMessage0: [
                  "Thank you for sharing this information with me {name}",
                  "Because you identified as being in crisis, someone from our duty of care team will call you within 5 working days",
                  "Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger"
                ],
                conditionalRecapMessage1: [
                  "Well, it's been a pleasure getting to know you {name}",
                  "You will be contacted within 5 working days to discuss next steps with you"
                ],
                conditionalRecapMessage2: [
                  "Well, it's been a pleasure getting to know you {name}",
                  "It looks like there hasn't been any activity for some time so l've referred you to {serviceName}"
                ],
                conditionalRecapMessage3: ["Well, it's been a pleasure getting to know you {name}"],
                conditionalRecapMessage4: ["Well, it's been a pleasure getting to know you {name}"],
                conditionalRecapMessage5: ["Well, it's been a pleasure getting to know you {name}"],
                conditionalRecapMessage6: ["Well, it's been a pleasure getting to know you {name}"],
                conditionalRecapMessage7: ["Well, it's been a pleasure getting to know you {name}"],
                conditionalRecapMessage8: ["Well, it's been a pleasure getting to know you {name}"],
                conditionalRecapMessage9: ["Well, it's been a pleasure getting to know you {name}"],
                conditionalRecapMessage10: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage11: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage12: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage13: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage14: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage15: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage16: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage17: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage18: [
                  "Well, it's been a pleasure getting to know you {name}"
                ],
                conditionalRecapMessage19: ["Well, it's been a pleasure getting to know you {name}"]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              },
              assessmentAndTreatments: {
                enableADSM: true
              },
              collectFeedback: {
                shouldAskAdditionalFeedback: true
              },
              goodbyeRecap: {
                conditions: {
                  conditionalRecapMessage0: [
                    {
                      name: "isCrisis",
                      type: "clinicalStore",
                      value: true
                    },
                    {
                      name: "isTriggerwordsCrisis",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "isHighRisk",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "isModerateRisk",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "treatment",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "requiresUrgentSupport",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "canKeepSelfSafe",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "referralSubmitted",
                      type: "state",
                      value: true
                    },
                    {
                      name: "isIdleSubmitted",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "needsToCall",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "needsAssessmentCall",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "appointment",
                      type: "state",
                      value: "skip"
                    }
                  ],
                  conditionalRecapMessage1: [
                    {
                      name: "isCrisis",
                      type: "clinicalStore",
                      value: false
                    },
                    {
                      name: "isTriggerwordsCrisis",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "isHighRisk",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "isModerateRisk",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "treatment",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "requiresUrgentSupport",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "canKeepSelfSafe",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "referralSubmitted",
                      type: "state",
                      value: true
                    },
                    {
                      name: "isIdleSubmitted",
                      type: "state",
                      value: false
                    },
                    {
                      name: "needsToCall",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "needsAssessmentCall",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "appointment",
                      type: "state",
                      value: "skip"
                    }
                  ],
                  conditionalRecapMessage2: [
                    {
                      name: "isCrisis",
                      type: "clinicalStore",
                      value: false
                    },
                    {
                      name: "isTriggerwordsCrisis",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "isHighRisk",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "isModerateRisk",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "treatment",
                      type: "clinicalStore",
                      value: "skip"
                    },
                    {
                      name: "requiresUrgentSupport",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "canKeepSelfSafe",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "referralSubmitted",
                      type: "state",
                      value: true
                    },
                    {
                      name: "isIdleSubmitted",
                      type: "state",
                      value: true
                    },
                    {
                      name: "needsToCall",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "needsAssessmentCall",
                      type: "state",
                      value: "skip"
                    },
                    {
                      name: "appointment",
                      type: "state",
                      value: "skip"
                    }
                  ]
                }
              }
            }
          }
        },
        {
          id: "2",
          type: NodeTypes.ChatFlow,
          data: {
            label:
              "Ask user if the bot was helpful and additional feedback (improvement suggestions)",
            chatFlow: ChatFlowsEnum.COLLECT_FEEDBACK,
            nodeId: "2"
          },
          position: {
            x: -100,
            y: 5
          },
          width: 240,
          height: 73,
          selected: false,
          positionAbsolute: {
            x: -100,
            y: 5
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.COLLECT_FEEDBACK,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                askFeedback: ["I hope I've been able to help you today"],
                saySorryToHearThat: [
                  "Sorry to hear that. How could I improve?",
                  "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
                ],
                sayUnderstood: [
                  "Understood. How could I improve?",
                  "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
                ],
                sayImGlad: [
                  "I'm really glad to hear that",
                  "What was the main benefit I was able to bring you?",
                  "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
                ]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              },
              assessmentAndTreatments: {
                enableADSM: true
              },
              collectFeedback: {
                shouldAskAdditionalFeedback: true
              }
            }
          }
        },
        {
          id: "3",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 0,
            y: 190
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 0,
            y: 190
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "1",
          sourceHandle: null,
          target: "4",
          targetHandle: "t-top",
          id: "reactflow__edge-1-4t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "4",
          sourceHandle: "s-bottom",
          target: "2",
          targetHandle: "t-top",
          id: "reactflow__edge-4s-bottom-2t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "2",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-top",
          id: "reactflow__edge-2s-bottom-3t-top"
        }
      ]
    },
    "Use advanced conditionals": {
      nodes: [
        {
          id: "1",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: -293,
            y: -240
          },
          selected: false,
          positionAbsolute: {
            x: -293,
            y: -240
          },
          dragging: false,
          width: 28,
          height: 28
        },
        {
          id: "5",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "referralSubmitted true -",
            nodeId: "5"
          },
          position: {
            x: -352,
            y: -155
          },
          width: 100,
          height: 100,
          selected: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.STATE,
                value: {
                  type: KeyType.Boolean,
                  context: "selfReferral-->66-submitReferral",
                  sourceKey: "referralSubmitted",
                  values: [true, false]
                }
              },
              operator: BooleanOperators.IS_TRUE,
              rightOperand: {
                storageType: RightHandContextType.STATE,
                value: {
                  type: KeyType.Text,
                  context: "-",
                  sourceKey: "-"
                },
                transformers: []
              }
            }
          },
          positionAbsolute: {
            x: -352,
            y: -155
          },
          dragging: false
        },
        {
          id: "8",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "isCrisis true -",
            nodeId: "8"
          },
          position: {
            x: -64,
            y: -75
          },
          width: 100,
          height: 100,
          selected: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.CLINICAL_STORE,
                value: {
                  type: KeyType.Boolean,
                  context: "clinicalStore",
                  contextKey: "clinicalStore",
                  sourceKey: "isCrisis"
                }
              },
              operator: BooleanOperators.IS_TRUE,
              rightOperand: {
                storageType: RightHandContextType.STATE,
                value: {
                  type: KeyType.Text,
                  context: "-",
                  sourceKey: "-"
                },
                transformers: []
              }
            }
          },
          positionAbsolute: {
            x: -64,
            y: -75
          },
          dragging: false
        },
        {
          id: "6",
          type: NodeTypes.Question,
          data: {
            label: ["I hope I've been able to help you today"],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "6",
            type: "message"
          },
          position: {
            x: -61,
            y: 211
          },
          width: 240,
          height: 71,
          selected: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: ["I hope I've been able to help you today"],
            questionName: "say i hope i helped",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "sayIHopeIHelped"
          },
          positionAbsolute: {
            x: -61,
            y: 211
          },
          dragging: false
        },
        {
          id: "10",
          type: NodeTypes.Question,
          data: {
            label: [
              "Well done for taking this important step towards better mental health",
              "You can now close the window"
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "10",
            type: "message"
          },
          position: {
            x: -73,
            y: 1719
          },
          width: 240,
          height: 120,
          selected: false,
          positionAbsolute: {
            x: -73,
            y: 1719
          },
          dragging: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: [
              "Well done for taking this important step towards better mental health",
              "You can now close the window"
            ],
            questionName: "say goodbye",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "sayGoodbye"
          }
        },
        {
          id: "17",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "requiresUrgentSupport equals -",
            nodeId: "17"
          },
          position: {
            x: 147,
            y: 5
          },
          width: 100,
          height: 100,
          selected: false,
          positionAbsolute: {
            x: 147,
            y: 5
          },
          dragging: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.STATE,
                value: {
                  type: KeyType.Text,
                  context: "eligibility-->8",
                  sourceKey: "requiresUrgentSupport",
                  values: [true, false]
                }
              },
              operator: TextOperators.EQUALS,
              rightOperand: {
                storageType: RightHandContextType.STRING,
                value: {
                  type: KeyType.Text,
                  context: "string",
                  sourceKey: "-",
                  customValue: "Yes"
                },
                transformers: []
              }
            }
          }
        },
        {
          id: "9",
          type: NodeTypes.Question,
          data: {
            label: ["Thank you for sharing this information with me {name}"],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "9",
            type: "message"
          },
          position: {
            x: 226,
            y: 212
          },
          width: 240,
          height: 71,
          selected: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: ["Thank you for sharing this information with me {name}"],
            questionName: "say thank you for sharing",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false
            },
            questionID: "sayThankYouForSharing"
          },
          positionAbsolute: {
            x: 226,
            y: 212
          },
          dragging: false
        },
        {
          id: "25",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "isCrisis true -",
            nodeId: "25"
          },
          position: {
            x: -573,
            y: -7
          },
          width: 100,
          height: 100,
          selected: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.CLINICAL_STORE,
                value: {
                  type: KeyType.Boolean,
                  context: "clinicalStore",
                  contextKey: "clinicalStore",
                  sourceKey: "isCrisis"
                }
              },
              operator: BooleanOperators.IS_TRUE,
              rightOperand: {
                storageType: RightHandContextType.STATE,
                value: {
                  type: KeyType.Text,
                  context: "-",
                  sourceKey: "-"
                },
                transformers: []
              }
            }
          },
          positionAbsolute: {
            x: -573,
            y: -7
          },
          dragging: false
        },
        {
          id: "24",
          type: NodeTypes.Question,
          data: {
            label: ["Thank you for sharing this information with me {name}"],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "24",
            type: "message"
          },
          position: {
            x: -721,
            y: 149
          },
          width: 240,
          height: 71,
          selected: false,
          positionAbsolute: {
            x: -721,
            y: 149
          },
          dragging: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: ["Thank you for sharing this information with me {name}"],
            questionName: "say thank you",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false,
              phonePromptPlaceholder: "Please type phone number"
            },
            questionID: "sayThankYou"
          }
        },
        {
          id: "18",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "isIdleSubmitted true -",
            nodeId: "18"
          },
          position: {
            x: -577,
            y: 291
          },
          width: 100,
          height: 100,
          selected: false,
          positionAbsolute: {
            x: -577,
            y: 291
          },
          dragging: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.STATE,
                value: {
                  sourceKey: "isIdleSubmitted",
                  type: KeyType.Unknown,
                  context: "customState"
                }
              },
              operator: BooleanOperators.IS_TRUE,
              rightOperand: {
                storageType: RightHandContextType.BOOLEAN,
                value: {
                  type: KeyType.Boolean,
                  context: "boolean",
                  sourceKey: "-",
                  customValue: "true"
                },
                transformers: []
              }
            }
          }
        },
        {
          id: "19",
          type: NodeTypes.Question,
          data: {
            label: [
              "It looks like there hasn’t been any activity for some time so I've referred you to {iaptName}"
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "19",
            type: "message"
          },
          position: {
            x: -797,
            y: 446
          },
          width: 240,
          height: 86,
          selected: false,
          positionAbsolute: {
            x: -797,
            y: 446
          },
          dragging: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: [
              "It looks like there hasn’t been any activity for some time so I've referred you to {iaptName}"
            ],
            questionName: "say idle submission",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false,
              phonePromptPlaceholder: "Please type phone number"
            },
            questionID: "sayIdleSubmission"
          }
        },
        {
          id: "22",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "riskLevel [text] equals -",
            nodeId: "22"
          },
          position: {
            x: -726,
            y: 607
          },
          width: 100,
          height: 100,
          selected: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.CLINICAL_STORE,
                value: {
                  type: KeyType.Unknown,
                  context: "customState",
                  contextKey: "clinicalStore",
                  sourceKey: "riskLevel [text]"
                }
              },
              operator: TextOperators.EQUALS,
              rightOperand: {
                storageType: RightHandContextType.STRING,
                value: {
                  type: KeyType.Text,
                  context: "string",
                  sourceKey: "-",
                  customValue: "LOW"
                },
                transformers: []
              }
            }
          },
          positionAbsolute: {
            x: -726,
            y: 607
          },
          dragging: false
        },
        {
          id: "23",
          type: NodeTypes.Question,
          data: {
            label: [
              "Someone from the service team will be in contact within 6 weeks, but hopefully sooner, to book your appointment with a relevant clinician"
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "23",
            type: "message"
          },
          position: {
            x: -895,
            y: 782
          },
          width: 240,
          height: 116,
          selected: false,
          positionAbsolute: {
            x: -895,
            y: 782
          },
          dragging: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: [
              "Someone from the service team will be in contact within 6 weeks, but hopefully sooner, to book your appointment with a relevant clinician"
            ],
            questionName: "say someone will contact you",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false,
              phonePromptPlaceholder: "Please type phone number"
            },
            questionID: "saySomeoneWillContactYou"
          }
        },
        {
          id: "27",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "riskLevel equals -",
            nodeId: "27"
          },
          position: {
            x: -656,
            y: 961
          },
          width: 100,
          height: 100,
          selected: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.CLINICAL_STORE,
                value: {
                  type: KeyType.Text,
                  context: "clinicalStore",
                  contextKey: "clinicalStore",
                  sourceKey: "riskLevel"
                }
              },
              operator: TextOperators.EQUALS,
              rightOperand: {
                storageType: RightHandContextType.STRING,
                value: {
                  type: KeyType.Text,
                  context: "string",
                  sourceKey: "-",
                  customValue: "HIGH"
                },
                transformers: []
              }
            }
          },
          positionAbsolute: {
            x: -656,
            y: 961
          },
          dragging: false
        },
        {
          id: "2",
          type: NodeTypes.ChatFlow,
          data: {
            label:
              "Ask user if the bot was helpful and additional feedback (improvement suggestions)",
            chatFlow: ChatFlowsEnum.COLLECT_FEEDBACK,
            nodeId: "2"
          },
          position: {
            x: -514,
            y: 1585
          },
          width: 240,
          height: 76,
          selected: false,
          positionAbsolute: {
            x: -514,
            y: 1585
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.COLLECT_FEEDBACK,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: {
                askPhoneNumber: "",
                askCanIContactYouOnPhoneNumber: "",
                askDoYouWantToShareEmail: "",
                askEmail: "",
                askEmailPermission: "",
                askLongTermMedicalCondition: "",
                askLongTermMedicalConditionOther: "",
                askSexuality: "",
                askPreferredContactMethod: "",
                askNHSNumber: "",
                askMainIssue: "",
                closingMessage: "",
                askDisabilityStatus: "",
                askDisability: "",
                askDisabilityOther: "",
                askPrimaryLanguage: "",
                askRequiresAnInterpreter: "",
                askInterpreterLanguage: "",
                askAbleToCommunicateInEnglish: "",
                askGender: "",
                askSameGenderAsBirth: "",
                askHasADHD: "",
                askHasASD: "",
                askWhatIsYourGoal: "",
                askPriorMHTreatment: "",
                sayReferralFailed: "",
                sayReferralSucceeded: "",
                askReligion: "",
                askPostCodeOfUser: "",
                askWantMeToReferYouAnyway: "",
                askWantMeToReferYouAnywaySpineSearch: "",
                askYourAreaOrGPPostCode: "",
                askPostCodeOfUserSpine: "",
                sayIntroToSpineSearch: "",
                saySearchSuccessful: "",
                sayICouldntFindYourGP: "",
                sayCannotReferYou: "",
                askAlcohol: "",
                askAlcoholFrequency: "",
                askAlcoholQuantity: "",
                askAlcoholImpactsLife: "",
                askSubstances: "",
                askSubstancesForMood: "",
                askSubstancesInfo: "",
                askSubstancesImpactLife: "",
                askNonPrescribedSubstances: "",
                askSubstancesAreMedication: "",
                askPrescribedMedication: "",
                askMedicationInfo: "",
                askMedicationWithinDosage: "",
                askMedicationNotTaking: "",
                askMedicationNotTakingInfo: "",
                askBirthday: "",
                askFullName: "",
                sayPleaseGiveFullName: "",
                sayNiceToMeetYou: "",
                askIsPreferredName: "",
                sayPleaseGivePreferredName: "",
                sayTriggerWordsDetected: "",
                askAreYouInCrisis: "",
                saySorryForTheMisunderstanding: "",
                saySorryToHear: "",
                sayCrisisNumbers: "",
                recapMessage: "",
                showTOSLinks: "",
                askResearchConsent: "",
                sayReady: "",
                askCanYouKeepYourselfSafe: "",
                sayCrisisRiskPathway: "",
                sayCrisisNumbersRiskPathway: "",
                sayLetsCarryOn: "",
                conditionalRecapMessage0: "",
                conditionalRecapMessage1: "",
                conditionalRecapMessage2: "",
                conditionalRecapMessage3: "",
                conditionalRecapMessage4: "",
                conditionalRecapMessage5: "",
                conditionalRecapMessage6: "",
                conditionalRecapMessage7: "",
                conditionalRecapMessage8: "",
                conditionalRecapMessage9: "",
                conditionalRecapMessage10: "",
                conditionalRecapMessage11: "",
                conditionalRecapMessage12: "",
                conditionalRecapMessage13: "",
                conditionalRecapMessage14: "",
                conditionalRecapMessage15: "",
                conditionalRecapMessage16: "",
                conditionalRecapMessage17: "",
                conditionalRecapMessage18: "",
                conditionalRecapMessage19: "",
                askFeedback: "",
                saySorryToHearThat: "",
                sayUnderstood: "",
                sayImGlad: "",
                sayBookAppointmentIntro: "",
                sayBookAppointmentSubmitReferralFailed: "",
                sayBookAppointmentGetSlotsFailed: "",
                askBookAppointmentSelectSlot: "",
                sayBookAppointmentConfirmSlot: "",
                sayBookAppointmentSlotUnavailable: "",
                sayBookAppointmentBookingError: "",
                sayBookAppointmentBookedSuccessfully: "",
                sayBookAppointmentGoodbyeLeave: "",
                smiIntroMessage: "",
                askUSAddress: "",
                askPermissionToMail: ""
              },
              messages: {
                askFeedback: ["I hope I've been able to help you today"],
                saySorryToHearThat: [
                  "Sorry to hear that. How could I improve?",
                  "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
                ],
                sayUnderstood: [
                  "Understood. How could I improve?",
                  "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
                ],
                sayImGlad: [
                  "I'm really glad to hear that",
                  "What was the main benefit I was able to bring you?",
                  "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
                ]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              },
              assessmentAndTreatments: {
                enableADSM: true
              },
              collectFeedback: {
                shouldAskAdditionalFeedback: true
              }
            }
          }
        },
        {
          id: "32",
          type: NodeTypes.Question,
          data: {
            label: [
              "Because you identified as being in crisis, someone from our duty of care team will call you within 1 working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact [999](tel:999) if you feel you are in danger"
            ],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "32",
            type: "message"
          },
          position: {
            x: -840,
            y: 1262
          },
          width: 240,
          height: 176,
          selected: false,
          positionAbsolute: {
            x: -840,
            y: 1262
          },
          dragging: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: [
              "Because you identified as being in crisis, someone from our duty of care team will call you within 1 working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact [999](tel:999) if you feel you are in danger"
            ],
            questionName: "say high risk message generic",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false,
              phonePromptPlaceholder: "Please type phone number"
            },
            questionID: "sayHighRiskMessageGeneric"
          }
        },
        {
          id: "28",
          type: NodeTypes.AdvancedCondition,
          data: {
            label: "riskLevel equals -",
            nodeId: "28"
          },
          position: {
            x: -519,
            y: 1061
          },
          width: 100,
          height: 100,
          selected: false,
          settings: {
            advancedCondition: {
              leftOperand: {
                transformers: [],
                storageType: LeftHandContextType.CLINICAL_STORE,
                value: {
                  type: KeyType.Text,
                  context: "clinicalStore",
                  contextKey: "clinicalStore",
                  sourceKey: "riskLevel"
                }
              },
              operator: TextOperators.EQUALS,
              rightOperand: {
                storageType: RightHandContextType.STRING,
                value: {
                  type: KeyType.Text,
                  context: "string",
                  sourceKey: "-",
                  customValue: "MODERATE"
                },
                transformers: []
              }
            }
          },
          positionAbsolute: {
            x: -519,
            y: 1061
          },
          dragging: false
        },
        {
          id: "20",
          type: NodeTypes.Question,
          data: {
            label: ["I've referred you to {iaptName}"],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "20",
            type: "message"
          },
          position: {
            x: -448,
            y: 472
          },
          width: 223,
          height: 56,
          selected: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: ["I've referred you to {iaptName}"],
            questionName: "say ive referred you",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false,
              phonePromptPlaceholder: "Please type phone number"
            },
            questionID: "sayIveReferredYou"
          },
          positionAbsolute: {
            x: -448,
            y: 472
          },
          dragging: false
        },
        {
          id: "26",
          type: NodeTypes.Question,
          data: {
            label: ["I hope I’ve been able to help you today"],
            choices: [
              {
                body: "Yes",
                value: "Yes"
              },
              {
                body: "No",
                value: "No"
              }
            ],
            checkboxOptions: [
              {
                body: "Yes",
                initialValue: false
              },
              {
                body: "No",
                initialValue: false
              }
            ],
            nodeId: "26",
            type: "message"
          },
          position: {
            x: -392,
            y: 156
          },
          width: 240,
          height: 71,
          selected: false,
          settings: {
            promptType: PromptType.Message,
            currentMessage: "",
            messages: ["I hope I’ve been able to help you today"],
            questionName: "say i hope i helped you",
            promptSettings: {
              checkboxOptions: [
                {
                  body: "Yes",
                  initialValue: false
                },
                {
                  body: "No",
                  initialValue: false
                }
              ],
              options: ["Yes", "No"],
              optionsToSelectIndividually: [],
              isUndoable: true,
              trackResponse: false,
              forceValue: false,
              crisisDetection: true,
              setPeople: false,
              textPromptWithInlineOption: false,
              textPromptPlaceholder: "Please specify",
              textPromptForceValue: false,
              phonePromptPlaceholder: "Please type phone number"
            },
            questionID: "sayIHopeIHelpedYou"
          },
          positionAbsolute: {
            x: -392,
            y: 156
          },
          dragging: false
        },
        {
          id: "3",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 32,
            y: 1951
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 32,
            y: 1951
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "1",
          sourceHandle: null,
          target: "5",
          targetHandle: "t-top",
          id: "reactflow__edge-1-5t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "5",
          sourceHandle: "s-right",
          target: "8",
          targetHandle: "t-top",
          id: "reactflow__edge-5s-right-8t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "8",
          sourceHandle: "s-bottom",
          target: "6",
          targetHandle: "t-top",
          id: "reactflow__edge-8s-bottom-6t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "6",
          sourceHandle: "s-bottom",
          target: "10",
          targetHandle: "t-top",
          id: "reactflow__edge-6s-bottom-10t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "8",
          sourceHandle: "s-right",
          target: "17",
          targetHandle: "t-top",
          id: "reactflow__edge-8s-right-17t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "17",
          sourceHandle: "s-bottom",
          target: "6",
          targetHandle: "t-top",
          id: "reactflow__edge-17s-bottom-6t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "17",
          sourceHandle: "s-right",
          target: "9",
          targetHandle: "t-top",
          id: "reactflow__edge-17s-right-9t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "9",
          sourceHandle: "s-bottom",
          target: "10",
          targetHandle: "t-top",
          id: "reactflow__edge-9s-bottom-10t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "5",
          sourceHandle: "s-bottom",
          target: "25",
          targetHandle: "t-top",
          id: "reactflow__edge-5s-bottom-25t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "25",
          sourceHandle: "s-bottom",
          target: "24",
          targetHandle: "t-top",
          id: "reactflow__edge-25s-bottom-24t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "24",
          sourceHandle: "s-bottom",
          target: "18",
          targetHandle: "t-top",
          id: "reactflow__edge-24s-bottom-18t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "18",
          sourceHandle: "s-bottom",
          target: "19",
          targetHandle: "t-top",
          id: "reactflow__edge-18s-bottom-19t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "19",
          sourceHandle: "s-bottom",
          target: "22",
          targetHandle: "t-top",
          id: "reactflow__edge-19s-bottom-22t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "22",
          sourceHandle: "s-bottom",
          target: "23",
          targetHandle: "t-top",
          id: "reactflow__edge-22s-bottom-23t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "23",
          sourceHandle: "s-bottom",
          target: "27",
          targetHandle: "t-top",
          id: "reactflow__edge-23s-bottom-27t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "2",
          sourceHandle: "s-bottom",
          target: "10",
          targetHandle: "t-top",
          id: "reactflow__edge-2s-bottom-10t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "32",
          sourceHandle: "s-bottom",
          target: "2",
          targetHandle: "t-top",
          id: "reactflow__edge-32s-bottom-2t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "27",
          sourceHandle: "s-right",
          target: "28",
          targetHandle: "t-top",
          id: "reactflow__edge-27s-right-28t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "28",
          sourceHandle: "s-right",
          target: "2",
          targetHandle: "t-top",
          id: "reactflow__edge-28s-right-2t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "22",
          sourceHandle: "s-right",
          target: "27",
          targetHandle: "t-top",
          id: "reactflow__edge-22s-right-27t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "18",
          sourceHandle: "s-right",
          target: "20",
          targetHandle: "t-top",
          id: "reactflow__edge-18s-right-20t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "20",
          sourceHandle: "s-bottom",
          target: "22",
          targetHandle: "t-top",
          id: "reactflow__edge-20s-bottom-22t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "25",
          sourceHandle: "s-right",
          target: "26",
          targetHandle: "t-top",
          id: "reactflow__edge-25s-right-26t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "26",
          sourceHandle: "s-bottom",
          target: "18",
          targetHandle: "t-top",
          id: "reactflow__edge-26s-bottom-18t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "10",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-top",
          id: "reactflow__edge-10s-bottom-3t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "28",
          sourceHandle: "s-bottom",
          target: "32",
          targetHandle: "t-top",
          id: "reactflow__edge-28s-bottom-32t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "27",
          sourceHandle: "s-bottom",
          target: "32",
          targetHandle: "t-top",
          id: "reactflow__edge-27s-bottom-32t-top"
        }
      ]
    }
  },
  [DiscussionSteps.Crisis]: {
    Main: {
      nodes: [
        {
          id: "61",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 0,
            y: -100
          },
          width: 28,
          height: 28
        },
        {
          id: "64",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Crisis detection signpost",
            chatFlow: ChatFlowsEnum.CHECK_CRISIS_DETECTION,
            nodeId: "64"
          },
          position: {
            x: -150,
            y: -20
          },
          width: 162,
          height: 39,
          selected: true,
          settings: {
            chatFlow: ChatFlowsEnum.CHECK_CRISIS_DETECTION,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                sayTriggerWordsDetected: ["I noticed that you wrote the words {trigger}"],
                askAreYouInCrisis: [
                  "This has made me think you might be at immediate risk of harm or in a life threatening urgent situation",
                  "Is this true?"
                ],
                saySorryForTheMisunderstanding: [
                  "Okay",
                  "My creators have taught me to listen carefully for specific words or phrases",
                  "In case you need help",
                  "Sorry for the misunderstanding",
                  "Ok, let's carry on with the mental health check in"
                ],
                saySorryToHear: [
                  "Sorry to hear that {name}",
                  "However {organisationName} does not provide urgent care"
                ],
                sayCrisisNumbers: [
                  "If you need urgent support, please dial NHS 111 and select Option 2",
                  "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
                  "Other helplines available:\n-You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email\n-If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help"
                ],
                recapMessage: [
                  "To recap:\nWe'll continue with the referral, however {organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above"
                ]
              },
              checkCrisisDetection: {
                crisisNumbersShared: "NHS 111, 999, A&E, Samaritans, Shout"
              }
            }
          },
          positionAbsolute: {
            x: -150,
            y: -20
          },
          dragging: false
        },
        {
          id: "63",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 10,
            y: 80
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 10,
            y: 80
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "61",
          sourceHandle: null,
          target: "64",
          targetHandle: "t-top",
          id: "reactflow__edge-61-64t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "64",
          sourceHandle: "s-bottom",
          target: "63",
          targetHandle: "t-top",
          id: "reactflow__edge-64s-bottom-63t-top"
        }
      ]
    }
  },
  [DiscussionSteps.Assessment]: {
    Main: {
      nodes: [
        {
          id: "7",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 0,
            y: -100
          },
          width: 28,
          height: 28
        },
        {
          id: "9",
          type: NodeTypes.ChatFlow,
          data: {
            label:
              "Assessment questionaires (PHQ9, GAD7, WASAS, Social Phobia, IRQ-A, ITQ, OCL, PCL5, PDSS, SHAI18, Specific Phobia)",
            chatFlow: ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS,
            nodeId: "9"
          },
          position: {
            x: -80,
            y: 70
          },
          width: 240,
          height: 91,
          selected: true,
          settings: {
            chatFlow: ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                askCanYouKeepYourselfSafe: [
                  "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 1 hour)?"
                ],
                sayCrisisRiskPathway: [
                  "Thank you for sharing this information",
                  "However {organisationName} does not provide urgent care"
                ],
                sayCrisisNumbersRiskPathway: [
                  "If you need urgent support, please dial NHS 111 and select Option 2",
                  "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
                  "Other helplines available:\nYou can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\nIf you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help"
                ],
                sayLetsCarryOn: ["Ok, let's carry on with the mental health check in"]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              },
              assessmentAndTreatments: {
                enableADSM: true
              }
            }
          },
          positionAbsolute: {
            x: -80,
            y: 70
          },
          dragging: false
        },
        {
          id: "10",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 65,
            y: 310
          },
          width: 32,
          height: 32,
          selected: false,
          positionAbsolute: {
            x: 65,
            y: 310
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "7",
          sourceHandle: null,
          target: "9",
          targetHandle: "t-top",
          id: "reactflow__edge-7-9t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "9",
          sourceHandle: "s-bottom",
          target: "10",
          targetHandle: "t-top",
          id: "reactflow__edge-9s-bottom-10t-top"
        }
      ]
    }
  },
  [DiscussionSteps.BookAppointment]: {
    Main: {
      nodes: [
        {
          width: 28,
          height: 28,
          id: "1",
          type: NodeTypes.FlowStart,
          data: {},
          position: {
            x: 0,
            y: -100
          },
          positionAbsolute: {
            x: 0,
            y: -100
          }
        },
        {
          width: 196,
          height: 39,
          id: "2",
          type: NodeTypes.ChatFlow,
          data: {
            label: "Book appointment with service",
            chatFlow: ChatFlowsEnum.BOOK_APPOINTMENT,
            nodeId: "3"
          },
          position: {
            x: -85,
            y: -5
          },
          selected: true,
          positionAbsolute: {
            x: -85,
            y: -5
          },
          dragging: false,
          settings: {
            chatFlow: ChatFlowsEnum.BOOK_APPOINTMENT,
            chatFlowSettings: {
              choicesMap: [],
              options: [],
              optionsToSelectIndividually: [],
              currentMessage: CURRENT_MESSAGES,
              messages: {
                sayBookAppointmentIntro: [
                  "I'm going to put you in touch with a qualified mental health professional"
                ],
                sayBookAppointmentSubmitReferralFailed: [
                  "Sorry, we're encountering a persistent issue and can't continue with the appointment booking",
                  "Someone from the service team will call you to organise your appointment"
                ],
                sayBookAppointmentGetSlotsFailed: [
                  "Hmmm... something went wrong while fetching your appointment slots",
                  "Someone from the service team will call you to organise your appointment"
                ],
                askBookAppointmentSelectSlot: [
                  "Let's see all the available appointment slots",
                  "If you can't find an appointment slot that suits you, please click \"continue\" and you'll be added to our waitlist. A local practitioner will be in touch to arrange one with you",
                  "Please use the arrows either side of the dates to view more available options"
                ],
                sayBookAppointmentConfirmSlot: [
                  "You have selected the following date and time",
                  "{slotDate} - {slotStartTime}",
                  "Is this correct?"
                ],
                sayBookAppointmentSlotUnavailable: [
                  "Hmmm... something went wrong while booking your appointment",
                  "Please try again"
                ],
                sayBookAppointmentBookingError: [
                  "Sorry {name}, we're encountering a persistent issue when trying to confirm your appointment booking",
                  "So we'll ask one of the {serviceName} team to give you a call to organise your appointment"
                ],
                sayBookAppointmentBookedSuccessfully: [
                  "Great {name}, your appointment has been booked successfully"
                ],
                sayBookAppointmentGoodbyeLeave: ["Okay"]
              },
              collectPhoneNumber: {
                requestSMSConsent: true,
                requestVoicemailConsent: true,
                supportedCountries: []
              },
              askLongTermMedicalConditions: {
                includeOther: true,
                shouldAskOtherDetails: true,
                shouldAskDoesLTCAffectsMood: true,
                shouldAskHowMuchLTCAffectsMood: true,
                shouldAskHowWellYouManageYourLTC: true
              },
              checkAlcoholConsumption: {
                shouldAskAlcoholFrequency: true,
                shouldAskAlcoholQuantity: true,
                shouldAskAlcoholImpactsLife: true
              },
              collectSubstances: {
                shouldAskSubstances: true,
                shouldAskSubstancesForMood: false,
                shouldAskSubstancesInfo: false,
                shouldAskSubstancesImpactLife: false,
                shouldAskNonPrescribedSubstances: true,
                shouldAskSubstancesAreMedication: true,
                shouldAskPrescribedMedication: true,
                shouldAskMedicationInfo: true,
                shouldAskMedicationWithinDosage: false,
                shouldAskMedicationNotTaking: false,
                shouldAskMedicationNotTakingInfo: false
              },
              collectName: {
                shouldAskPreferredName: true,
                shouldSayNiceToMeetYou: true
              },
              getPermissions: {
                shouldAskResearchConsent: true,
                shouldSayReady: true
              },
              assessmentAndTreatments: {
                enableADSM: true
              },
              collectFeedback: {
                shouldAskAdditionalFeedback: true
              },
              spineSearch: {
                shouldUseServiceSearchFallback: true
              },
              bookAppointment: {}
            }
          }
        },
        {
          width: 32,
          height: 32,
          id: "3",
          type: NodeTypes.FlowEnd,
          data: {},
          position: {
            x: 0,
            y: 110
          },
          selected: false,
          positionAbsolute: {
            x: 0,
            y: 110
          },
          dragging: false
        }
      ],
      edges: [
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "1",
          sourceHandle: null,
          target: "2",
          targetHandle: "t-top",
          id: "reactflow__edge-1-2t-top"
        },
        {
          type: EdgeTypes.Custom,
          markerEnd: {
            type: "arrowclosed",
            strokeWidth: 2
          },
          source: "2",
          sourceHandle: "s-bottom",
          target: "3",
          targetHandle: "t-top",
          id: "reactflow__edge-2s-bottom-3t-top"
        }
      ]
    }
  }
}

export default defaultFlows
