import React from "react"
import Questionnaire from "./Questionnaire/Questionnaire"
import styles from "./CustomQuestionaires.module.scss"
import {
  AssessmentType,
  CUSTOM_ASSESSMENT_QUESTIONNAIRES,
  QuestionnaireDialogue
} from "@limbic/types"

interface CustomQuestionnairesProps {
  handleCustomQuestionnaire: (type: AssessmentType, questionnaire: QuestionnaireDialogue) => void
}

const CustomQuestionnaires = ({
  handleCustomQuestionnaire
}: CustomQuestionnairesProps): JSX.Element => {
  return (
    <div>
      <div className={styles.custom_Questionnaire_heading}>
        Alternate Questionnaires for Assessments
      </div>
      {Object.entries(CUSTOM_ASSESSMENT_QUESTIONNAIRES).map(([assessmentType, options]) => (
        <div key={assessmentType} className={styles.custom_Questionnaire_Container}>
          <Questionnaire
            assessmentType={assessmentType as AssessmentType}
            options={options}
            onSelect={handleCustomQuestionnaire}
          />
        </div>
      ))}
    </div>
  )
}

export default CustomQuestionnaires
