// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WIvG1BWlP_0ln3gcyAYB{display:flex;flex-flow:column;align-items:flex-start;margin:0 6px 6px 0}.r8cPLsx8kuK9T09H2v7N{border-radius:5px}.r8cPLsx8kuK9T09H2v7N>div:first-child{min-height:40px;border-radius:5px}.r8cPLsx8kuK9T09H2v7N>div:first-child:hover{background:rgba(204,204,204,.4)}.r8cPLsx8kuK9T09H2v7N>div:first-child input{font-size:.85rem}.r8cPLsx8kuK9T09H2v7N input+div{background-color:red;display:none}.r8cPLsx8kuK9T09H2v7N>div>div[role=button]{min-height:28px;height:28px;max-width:85%}`, "",{"version":3,"sources":["webpack://./src/app/views/ClinicalPaths/components/CustomPaths/components/AutoComplete/AutoComplete.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CAEA,sCACE,eAAA,CACA,iBAAA,CAEA,4CACE,+BAAA,CAGF,4CACE,gBAAA,CAKJ,gCACE,oBAAA,CACA,YAAA,CAIJ,2CACE,eAAA,CACA,WAAA,CACA,aAAA","sourcesContent":[".inputSection {\n  display: flex;\n  flex-flow: column;\n  align-items: flex-start;\n  margin: 0 6px 6px 0;\n}\n\n.textField {\n  border-radius: 5px;\n\n  > div:first-child {\n    min-height: 40px;\n    border-radius: 5px;\n\n    &:hover {\n      background: rgba(204, 204, 204, 0.4);\n    }\n\n    & input {\n      font-size: 0.85rem;\n    }\n  }\n\n  // arrow button\n  input + div {\n    background-color: red;\n    display: none;\n  }\n}\n\n.textField > div > div[role=\"button\"] {\n  min-height: 28px;\n  height: 28px;\n  max-width: 85%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputSection": `WIvG1BWlP_0ln3gcyAYB`,
	"textField": `r8cPLsx8kuK9T09H2v7N`
};
export default ___CSS_LOADER_EXPORT___;
