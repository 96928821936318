// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IpLqCBAQAQ7CZ7U_imQp{height:auto;margin-left:10px}.Jk5x5F83EBVli4XPvnhQ{display:flex;flex-wrap:wrap;align-items:center;justify-content:left;padding:5px}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/AssessmentAndTreatmentsCustomQuestionnaires/CustomQuestionnaires/Questionnaire/Questionnaire.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,oBAAA,CACA,WAAA","sourcesContent":[".select {\n  height: auto;\n  margin-left: 10px;\n}\n\n.questionnaire_container{\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: left;\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `IpLqCBAQAQ7CZ7U_imQp`,
	"questionnaire_container": `Jk5x5F83EBVli4XPvnhQ`
};
export default ___CSS_LOADER_EXPORT___;
