import { observer } from "mobx-react"
import React from "react"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { FormControl, TextField, Typography } from "@mui/material"
import styles from "./SetRiskLevel.module.scss"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { EditorType } from "../../../../models/NodeEditors"
import FormControlWithCheckboxControlledInput from "../../../ChatFlowEditor/components/FormControlWithCheckBox/FormControlWithCheckboxControlledInput"
import SolidDivider from "../../../../../SolidDivider"

const options = ["MODERATE", "HIGH"]

function SetRiskLevel() {
  const nodeEditorStore = useNodeEditorStore()

  const onChangeRiskLevel = e => {
    nodeEditorStore.updateState(EditorType.ACTION, {
      ...nodeEditorStore.actionEditorState,
      actionSetRiskLevel: e.target.value
    })
  }

  const onChangeRiskLevelReason = e => {
    nodeEditorStore.updateState(EditorType.ACTION, {
      ...nodeEditorStore.actionEditorState,
      actionSetRiskLevelReason: e.target.value
    })
  }

  const onToggleIsCrisis = (_e, v: boolean) => {
    nodeEditorStore.updateState(EditorType.ACTION, {
      ...nodeEditorStore.actionEditorState,
      actionSetRiskLevelIsCrisis: v
    })
  }

  return (
    <div className={styles.setRiskLevelContainer}>
      <Typography variant="subtitle1">
        <h3>Set Risk Level</h3>
        <p>
          The purpose of this action is to allow us to set the risk level on demand as needed in
          various points in the conversation. This means when risk is set from this action,{" "}
          <b>a risk email will be sent to the service</b> as well (if it hasn't already been sent in
          a previous step).
        </p>
        <ul>
          <li className={styles.warningInfo}>Please make sure you know what you are doing!</li>
          <li>
            You can't downgrade <code>riskLevel</code> and <code>isCrisis</code>
          </li>
          <li>
            The risk level and the reason are <b>NOT</b> going to be updated if at that point the
            risk level is already set by a previous action or by some hardcoded flow in the chatbot
            unless you're upgrading from <code>MODERATE</code> to <code>HIGH</code>
          </li>
          <li>
            <code>isCrisis</code> is updated separately so even if risk exists from a previous step,
            you can still set it as long as you go from disabled to enabled - never the other way
            around.
          </li>
          <li>
            You can use these properties in an advanced condition, via the{" "}
            <code>clinicalStore</code> properties <code>riskLevel</code>,{" "}
            <code>riskLevelReason</code>, and <code>isCrisis</code>
          </li>
          <li>A reason must always be provided to keep track of why the risk level changes</li>
          <li>These properties will also be updated in Mixpanel</li>
        </ul>

        <p className={styles.warningExplanation}>
          <em>
            If you're testing this action out, and you don't receive a risk email, make sure the
            selected IAPT in the eligibility tab of the configuration section inludes email
            addresses for risk emails. If you still don't receive a risk email, please contact the
            engineering team.
          </em>
        </p>
      </Typography>

      <SolidDivider />

      <FormControl fullWidth className={styles.setRiskLevelSelectContainer}>
        <p>
          <strong>Risk Level</strong>
        </p>
        <Select
          id="select-risk-level-input"
          className={styles.select}
          variant="outlined"
          onChange={onChangeRiskLevel}
          value={nodeEditorStore.actionEditorState.actionSetRiskLevel}>
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth className={styles.setRiskLevelReasonContainer}>
        <TextField
          type="text"
          variant="standard"
          value={nodeEditorStore.actionEditorState.actionSetRiskLevelReason}
          label="Risk Level Reason"
          placeholder="Type in a reason for the risk level"
          onChange={onChangeRiskLevelReason}
        />
      </FormControl>
      <FormControlWithCheckboxControlledInput
        name="actionSetRiskLevelIsCrisis"
        value={!!nodeEditorStore.actionEditorState.actionSetRiskLevelIsCrisis}
        label="Is Crisis"
        labelPlacement="end"
        className={styles.setRiskLevelIsCrisisCheckbox}
        onToggle={onToggleIsCrisis}
      />
    </div>
  )
}

export default observer(SetRiskLevel)
