// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LSp_U5barI0euRhfxNDl{display:flex;align-items:flex-end;justify-content:center;flex-flow:column;width:calc(50% - 12px);margin:8px 0}.T3TlLVsJKbgdZJzL0FPv{width:100% !important;margin-left:1em !important;margin-bottom:1em !important}.QJ3K9b_BxieQn57LI168{margin-top:1em;margin-right:.5em !important;width:50%}.yK3O_2BvBNLvBahXPnLt{margin-top:1em;margin-left:.5em;width:50%;height:4em}.tdLAqlk9h6bKAImlBiL7{display:flex;align-items:center;justify-content:space-between;flex-flow:row;width:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ActionEditor/components/SetStateKey/SetStateKey.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oBAAA,CACA,sBAAA,CACA,gBAAA,CACA,sBAAA,CACA,YAAA,CAGF,sBACE,qBAAA,CACA,0BAAA,CACA,4BAAA,CAGF,sBACE,cAAA,CACA,4BAAA,CACA,SAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,SAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,aAAA,CACA,UAAA","sourcesContent":[".setStateContainer {\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  flex-flow: column;\n  width: calc(50% - 12px);\n  margin: 8px 0;\n}\n\n.formControlStateKey {\n  width: 100% !important;\n  margin-left: 1em !important;\n  margin-bottom: 1em !important;\n}\n\n.formControlStateType {\n  margin-top: 1em;\n  margin-right: 0.5em !important;\n  width: 50%;\n}\n\n.formControlStateValue {\n  margin-top: 1em;\n  margin-left: 0.5em;\n  width: 50%;\n  height: 4em;\n}\n\n.valueContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-flow: row;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setStateContainer": `LSp_U5barI0euRhfxNDl`,
	"formControlStateKey": `T3TlLVsJKbgdZJzL0FPv`,
	"formControlStateType": `QJ3K9b_BxieQn57LI168`,
	"formControlStateValue": `yK3O_2BvBNLvBahXPnLt`,
	"valueContainer": `tdLAqlk9h6bKAImlBiL7`
};
export default ___CSS_LOADER_EXPORT___;
