// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qLENs83ntsQwsZQR8z0Q{display:flex;flex-direction:column;align-content:center;width:80%}.qLENs83ntsQwsZQR8z0Q code{background:rgba(0,0,0,.1);padding:2px 4px;border-radius:4px;font-size:.9em}.rM3V0QviLY9mwynrrhaw{margin:0;padding:0;font-weight:bold;font-size:16px;color:red}.FuDp9nLhXgUR1cXzAmlu{margin:0;color:gray}.w_6ib58ebwTu0vaN6iI7{display:flex;flex:1;flex-direction:row;margin-top:16px}.w_6ib58ebwTu0vaN6iI7 .nnrlrYvhPEfiaznQs_Um{height:auto;margin-left:16px}.y9J_BR1SJEIJDVmXK8du{display:flex;flex:1;margin-top:16px}.Im68Xw0f23cvm_2fzE2z{margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ActionEditor/components/SetRiskLevel/SetRiskLevel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,SAAA,CAEA,2BACE,yBAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CAIJ,sBACE,QAAA,CACA,SAAA,CACA,gBAAA,CACA,cAAA,CACA,SAAA,CAGF,sBACE,QAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,MAAA,CACA,kBAAA,CACA,eAAA,CAEA,4CACE,WAAA,CACA,gBAAA,CAIJ,sBACE,YAAA,CACA,MAAA,CACA,eAAA,CAGF,sBACE,eAAA","sourcesContent":[".setRiskLevelContainer {\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  width: 80%;\n\n  code {\n    background: rgba(0, 0, 0, 0.1);\n    padding: 2px 4px;\n    border-radius: 4px;\n    font-size: 0.9em;\n  }\n}\n\n.warningInfo {\n  margin: 0;\n  padding: 0;\n  font-weight: bold;\n  font-size: 16px;\n  color: red;\n}\n\n.warningExplanation {\n  margin: 0;\n  color: gray;\n}\n\n.setRiskLevelSelectContainer {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  margin-top: 16px;\n\n  .select {\n    height: auto;\n    margin-left: 16px;\n  }\n}\n\n.setRiskLevelReasonContainer {\n  display: flex;\n  flex: 1;\n  margin-top: 16px;\n}\n\n.setRiskLevelIsCrisisCheckbox {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setRiskLevelContainer": `qLENs83ntsQwsZQR8z0Q`,
	"warningInfo": `rM3V0QviLY9mwynrrhaw`,
	"warningExplanation": `FuDp9nLhXgUR1cXzAmlu`,
	"setRiskLevelSelectContainer": `w_6ib58ebwTu0vaN6iI7`,
	"select": `nnrlrYvhPEfiaznQs_Um`,
	"setRiskLevelReasonContainer": `y9J_BR1SJEIJDVmXK8du`,
	"setRiskLevelIsCrisisCheckbox": `Im68Xw0f23cvm_2fzE2z`
};
export default ___CSS_LOADER_EXPORT___;
