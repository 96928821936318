// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UqQOFzHET5NJzl4sQyQq{display:flex;flex-flow:row}.kud5V_thxtxQ77swaunt{position:relative;width:100%;margin:8px 4px}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/QuestionEditor/components/PromptSettings/Date/Date.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".formContainer{\n  display: flex;\n  flex-flow: row;\n}\n\n.formControl {\n  position: relative;\n  width: 100%;\n  margin: 8px 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `UqQOFzHET5NJzl4sQyQq`,
	"formControl": `kud5V_thxtxQ77swaunt`
};
export default ___CSS_LOADER_EXPORT___;
