// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EJSWXK6y904_hzKEA_Uc{display:flex;align-items:center;justify-content:center;width:100%;margin:8px 0}.unOj5esinlTodT71XcUI{width:40%}.X2_sQ10kpK7qS7xZjSlG{width:50%;margin:8px !important}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ActionEditor/components/SetLanguage/SetLanguage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,YAAA,CAGF,sBACE,SAAA,CAGF,sBACE,SAAA,CACA,qBAAA","sourcesContent":[".setLanguageContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin: 8px 0;\n}\n\n.formControlSetIAPTIDContainer {\n  width: 40%;\n}\n\n.select {\n  width: 50%;\n  margin: 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setLanguageContainer": `EJSWXK6y904_hzKEA_Uc`,
	"formControlSetIAPTIDContainer": `unOj5esinlTodT71XcUI`,
	"select": `X2_sQ10kpK7qS7xZjSlG`
};
export default ___CSS_LOADER_EXPORT___;
