import { styled } from "@stitches/react"
import { mauve } from "@radix-ui/colors"

interface IContextMenuRowProps {
  label: string
  left?: string | JSX.Element
  right?: string | JSX.Element
}

export function MenuRow(props: IContextMenuRowProps): JSX.Element {
  return (
    <>
      {props.left ? <LeftSlot>{props.left}</LeftSlot> : null}
      {props.label}
      <RightSlot>{props.right}</RightSlot>
    </>
  )
}

const LeftSlot = styled("div", {
  position: "absolute",
  left: 0,
  paddingLeft: 4,
  color: mauve.mauve12,
  "[data-highlighted] > &": { color: "white" },
  "[data-disabled] &": { color: mauve.mauve8 }
})

const RightSlot = styled("div", {
  marginLeft: "auto",
  paddingLeft: 20,
  color: mauve.mauve11,
  "[data-highlighted] > &": { color: "white" },
  "[data-disabled] &": { color: mauve.mauve8 }
})
