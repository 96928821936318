// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hAmvC286nimUhzsOogx0{display:flex;flex-flow:row;align-items:center;font-weight:600}.EQ_srpfD089wCZKngqcy{color:red}.t1gbs2XsJ6FME8aVvn_g{color:gray;font-size:14px !important;margin:0 0 2px 4px}`, "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/InputLabel/InputLabel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,SAAA,CAGF,sBACE,UAAA,CACA,yBAAA,CACA,kBAAA","sourcesContent":[".inputLabel {\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n  font-weight: 600;\n}\n\n.inputLabelError {\n  color: red;\n}\n\n.infoIcon {\n  color: gray;\n  font-size: 14px !important;\n  margin: 0 0 2px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `hAmvC286nimUhzsOogx0`,
	"inputLabelError": `EQ_srpfD089wCZKngqcy`,
	"infoIcon": `t1gbs2XsJ6FME8aVvn_g`
};
export default ___CSS_LOADER_EXPORT___;
