// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sxu6TPbmErDWapOgYkTK{display:flex;flex-flow:row;justify-content:center;align-items:center;width:944px;padding:6px 0;position:relative;gap:20px}@media only screen and (max-width: 960px){.Sxu6TPbmErDWapOgYkTK{width:calc(100vw - 16px)}}@media only screen and (max-width: 800px){.Sxu6TPbmErDWapOgYkTK{width:80vw}}.cUnHCyKA9RFwZlOikjl0{min-width:450px;flex:0 1 450px;align-self:start}.iFW8SjMPN7GoK3Q33ixt{flex:1 1;display:flex;flex-direction:column;align-items:end;margin-top:45px;min-height:320px}.QKvk9HqQLhldzUfnyYHX{display:flex;flex-direction:row;gap:10px}.QKvk9HqQLhldzUfnyYHX>div{display:flex;flex-direction:row;gap:10px}`, "",{"version":3,"sources":["webpack://./src/app/components/SnippetInjectionBox/SnippetInjectionBox.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CAOA,QAAA,CANA,0CARF,sBASI,wBAAA,CAAA,CAEF,0CAXF,sBAYI,UAAA,CAAA,CAKJ,sBACE,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,sBACE,QAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,0BACE,YAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".snippetConfigurationContainer {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  align-items: center;\n  width: calc(960px - 16px);\n  padding: 6px 0;\n  position: relative;\n  @media only screen and (max-width: 960px) {\n    width: calc(100vw - 16px);\n  }\n  @media only screen and (max-width: 800px) {\n    width: 80vw;\n  }\n  gap: 20px;\n}\n\n.snippetEditor {\n  min-width: 450px;\n  flex: 0 1 450px;\n  align-self: start;\n}\n\n.snippetList {\n  flex: 1 1;\n  display: flex;\n  flex-direction: column;\n  align-items: end;\n  margin-top: 45px;\n  min-height: 320px;\n}\n\n.snippetPositionSelector {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n\n  > div {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snippetConfigurationContainer": `Sxu6TPbmErDWapOgYkTK`,
	"snippetEditor": `cUnHCyKA9RFwZlOikjl0`,
	"snippetList": `iFW8SjMPN7GoK3Q33ixt`,
	"snippetPositionSelector": `QKvk9HqQLhldzUfnyYHX`
};
export default ___CSS_LOADER_EXPORT___;
