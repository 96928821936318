// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e_XEadt2HF4nwL4XULrG{position:fixed;top:0;display:flex;flex-flow:column;width:100%;height:128px;background-color:#fff;z-index:2}.A6LpNhgjVVO1wQ9P6iGH{display:flex;flex-flow:row;align-items:center;justify-content:space-between;width:100%;height:64px;text-align:center;padding:0 24px;box-sizing:border-box}.btjkcXckqrl7VO8HeYHL{display:flex;flex-flow:row;justify-content:left;min-width:128px}.WQrc30axMzQXdjHjEhbV{display:flex;flex-flow:row;justify-content:right;align-items:center;width:auto}.YcOfaG2T2gJsI_HqJF8N{display:flex}.QmPNKt5Gj2O4Q8cmz1By{padding-left:8px;height:40px}.zsUnYeIOz7lz7UyCrp8R{padding:1em}`, "",{"version":3,"sources":["webpack://./src/app/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,KAAA,CACA,YAAA,CACA,gBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,SAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,oBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CAGF,sBACE,YAAA,CAGF,sBACE,gBAAA,CACA,WAAA,CAGF,sBACE,WAAA","sourcesContent":[".header {\n  position: fixed;\n  top: 0;\n  display: flex;\n  flex-flow: column;\n  width: 100%;\n  height: 128px;\n  background-color: white;\n  z-index: 2;\n}\n\n.headerMain {\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 64px;\n  text-align: center;\n  padding: 0 24px;\n  box-sizing: border-box;\n}\n\n.headerLeft {\n  display: flex;\n  flex-flow: row;\n  justify-content: left;\n  min-width: 128px;\n}\n\n.headerRight {\n  display: flex;\n  flex-flow: row;\n  justify-content: right;\n  align-items: center;\n  width: auto;\n}\n\n.link {\n  display: flex;\n}\n\n.logo {\n  padding-left: 8px;\n  height: 40px;\n}\n\n.serviceInfo {\n  padding: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `e_XEadt2HF4nwL4XULrG`,
	"headerMain": `A6LpNhgjVVO1wQ9P6iGH`,
	"headerLeft": `btjkcXckqrl7VO8HeYHL`,
	"headerRight": `WQrc30axMzQXdjHjEhbV`,
	"link": `YcOfaG2T2gJsI_HqJF8N`,
	"logo": `QmPNKt5Gj2O4Q8cmz1By`,
	"serviceInfo": `zsUnYeIOz7lz7UyCrp8R`
};
export default ___CSS_LOADER_EXPORT___;
