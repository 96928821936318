// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GRqzeAZ3NLUCEkejb4As{display:flex;flex-flow:row;justify-content:center;align-items:center;padding:0 8px;width:100%}.qbe_OdiD33BLyNPo85qt{height:36px;width:100%;border-radius:15px;margin:12px 6px;border:1px solid #d3d3d3;padding:8px 16px}.R2Pj4bMYwC_aLOVm56NP{display:flex;flex-flow:column;align-items:center}.N2DED5za3XWvvBaU4pLs{padding:2px !important}.N2DED5za3XWvvBaU4pLs svg{height:24px}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/MessageForm/MessageForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CACA,UAAA,CAGF,sBACE,WAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CACA,wBAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,kBAAA,CAGF,sBACE,sBAAA,CACA,0BACE,WAAA","sourcesContent":[".addMessageForm {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  align-items: center;\n  padding: 0 8px;\n  width: 100%;\n}\n\n.messageInput {\n  height: 36px;\n  width: 100%;\n  border-radius: 15px;\n  margin: 12px 6px;\n  border: 1px solid lightgray;\n  padding: 8px 16px;\n}\n\n.messageFormContainer {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.addButton {\n  padding: 2px !important;\n  & svg {\n    height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addMessageForm": `GRqzeAZ3NLUCEkejb4As`,
	"messageInput": `qbe_OdiD33BLyNPo85qt`,
	"messageFormContainer": `R2Pj4bMYwC_aLOVm56NP`,
	"addButton": `N2DED5za3XWvvBaU4pLs`
};
export default ___CSS_LOADER_EXPORT___;
