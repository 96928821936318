// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hKpWfQFSoiw8fZ1CDZXB{width:100%;margin-left:0 !important;text-align:right}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/FormControlWithCheckBox/FormControlWithCheckBox.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".formControlLabel {\n  width: 100%;\n  margin-left: 0 !important;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControlLabel": `hKpWfQFSoiw8fZ1CDZXB`
};
export default ___CSS_LOADER_EXPORT___;
