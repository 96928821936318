// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ziVPRa_doK6qe_qyKHLQ{margin-bottom:1em;color:rgba(0,0,0,.7)}.G14hQchbJ3OU6KOYBfGu{display:flex;flex-flow:column;justify-content:flex-end;align-items:flex-end;padding-right:16px;margin:0 0 16px 0}.jjiicRyOmOxfatNFoAkg{display:flex;flex-flow:column;justify-content:flex-end;align-items:center}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/AssessmentAndTreatments/AssessmentAndTreatments.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,oBAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,wBAAA,CACA,oBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":[".assessmentAndTreatmentsTitle {\n  margin-bottom: 1em;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.captionContainer {\n  display: flex;\n  flex-flow: column;\n  justify-content: flex-end;\n  align-items: flex-end;\n  padding-right: 16px;\n  margin: 0 0 16px 0;\n}\n\n.crisisNumbersSharedContainer {\n  display: flex;\n  flex-flow: column;\n  justify-content: flex-end;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assessmentAndTreatmentsTitle": `ziVPRa_doK6qe_qyKHLQ`,
	"captionContainer": `G14hQchbJ3OU6KOYBfGu`,
	"crisisNumbersSharedContainer": `jjiicRyOmOxfatNFoAkg`
};
export default ___CSS_LOADER_EXPORT___;
