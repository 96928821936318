// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iQ8Tn64oIqqF0N2EImEM{display:flex;flex-flow:column;align-items:center;justify-content:center;margin:16px 0}.bw6E7rvgXOJWi5PgH2Ph{margin-bottom:1em}.DMgWnmRtUkOoF6kNbpHD{margin-top:1em;height:2em}`, "",{"version":3,"sources":["webpack://./src/app/components/Header/components/PublishDialogue/PublishDialogue.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,cAAA,CACA,UAAA","sourcesContent":[".publishContainer {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  margin: 16px 0;\n}\n\n.lastPublishDate {\n  margin-bottom: 1em;\n}\n\n.publishSuccessful {\n  margin-top: 1em;\n  height: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"publishContainer": `iQ8Tn64oIqqF0N2EImEM`,
	"lastPublishDate": `bw6E7rvgXOJWi5PgH2Ph`,
	"publishSuccessful": `DMgWnmRtUkOoF6kNbpHD`
};
export default ___CSS_LOADER_EXPORT___;
