// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quktI0cWITpvnm_F1s9Q{padding-bottom:9px;width:100%;text-align:left;font-weight:bold}.cQa0yG8snT86J0kTNogK{display:flex;align-items:center;width:100%}.w4uowvg01miKBXl_hKaO{height:36px;flex:1;border-radius:15px;margin:12px 6px;border:1px solid #d3d3d3;padding:8px 16px}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CollectPhoneNumber/CollectPhoneNumber.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,UAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CAGF,sBACE,WAAA,CACA,MAAA,CACA,kBAAA,CACA,eAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".settingsTitle {\n  padding-bottom: 9px;\n  width: 100%;\n  text-align: left;\n  font-weight: bold;\n}\n\n.keyInputContainer {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.keyInput {\n  height: 36px;\n  flex: 1;\n  border-radius: 15px;\n  margin: 12px 6px;\n  border: 1px solid lightgray;\n  padding: 8px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsTitle": `quktI0cWITpvnm_F1s9Q`,
	"keyInputContainer": `cQa0yG8snT86J0kTNogK`,
	"keyInput": `w4uowvg01miKBXl_hKaO`
};
export default ___CSS_LOADER_EXPORT___;
