// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KWdwrfMhIBIITD2X3BJJ{display:flex;justify-content:center;width:100%}.KWdwrfMhIBIITD2X3BJJ span{padding:4px 12px;background-color:#d3d3d3;border-radius:10px}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/OptionsPlaceholder/OptionsPlaceholder.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,UAAA,CACA,2BACE,gBAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":[".optionsPlaceholderContainer {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  span {\n    padding: 4px 12px;\n    background-color: lightgray;\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsPlaceholderContainer": `KWdwrfMhIBIITD2X3BJJ`
};
export default ___CSS_LOADER_EXPORT___;
