// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drrt5O_XtYOQAySRMH51{display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center}.CerYhiwHoUrKdajCH4wL{align-items:center;border-radius:22px;border-color:rgba(7,34,48,.1);color:#fff;cursor:default;display:flex;text-align:center;flex-direction:row;max-width:250px;padding:10px 16px;margin:4px;opacity:1}`, "",{"version":3,"sources":["webpack://./src/app/components/BotPlayground/components/BotOption/BotOptions.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,cAAA,CACA,sBAAA,CAGF,sBACE,kBAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,cAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CACA,SAAA","sourcesContent":[".botInputsContainer {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.botButton {\n  align-items: center;\n  border-radius: 22px;\n  border-color: rgba(7, 34, 48, 0.1);\n  color: white;\n  cursor: default;\n  display: flex;\n  text-align: center;\n  flex-direction: row;\n  max-width: 250px;\n  padding: 10px 16px;\n  margin: 4px;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botInputsContainer": `drrt5O_XtYOQAySRMH51`,
	"botButton": `CerYhiwHoUrKdajCH4wL`
};
export default ___CSS_LOADER_EXPORT___;
