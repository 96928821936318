// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nfAORTSRHPUkIxc7oN7N{font-size:10px !important}.nGB7X39pjNEx_UNJm1qP{margin-left:0 !important}.NuElfx7M0GavpuZQAC9j{background-color:rgba(0,0,0,0)}.NuElfx7M0GavpuZQAC9j:hover{background-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/CrisisIndicator/CrisisIndicator.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,8BAAA,CACA,4BACE,8BAAA","sourcesContent":[".formControlLabel {\n  font-size: 10px !important;\n}\n\n.formControlLabelPlacementStart {\n  margin-left: 0 !important;\n}\n\n.crisisCheckbox {\n  background-color: transparent;\n  &:hover {\n    background-color: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControlLabel": `nfAORTSRHPUkIxc7oN7N`,
	"formControlLabelPlacementStart": `nGB7X39pjNEx_UNJm1qP`,
	"crisisCheckbox": `NuElfx7M0GavpuZQAC9j`
};
export default ___CSS_LOADER_EXPORT___;
