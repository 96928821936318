// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TzkJEz5FjBmlXKaFUvqg{display:flex;align-items:center;justify-content:space-between;width:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/Accordion/Accordion.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA","sourcesContent":[".accordionSummaryContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionSummaryContainer": `TzkJEz5FjBmlXKaFUvqg`
};
export default ___CSS_LOADER_EXPORT___;
