// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eTe2BpWFY2plWLYQM0vR{display:flex;flex-flow:row;justify-content:flex-end;align-items:center}.eTe2BpWFY2plWLYQM0vR p{margin-right:8px}._bxFo6abZ3WZKTYKwVYo{width:4.5em}.m944tv8DkFEZkfmJlU0T{margin-bottom:1em;color:rgba(0,0,0,.7)}.CQrUufYyzBkguQsXXIt1{color:rgba(0,0,0,.4)}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/SeviceSearch/ServiceSearch.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,wBAAA,CACA,kBAAA,CACA,wBACE,gBAAA,CAIJ,sBACE,WAAA,CAGF,sBACE,iBAAA,CACA,oBAAA,CAGF,sBACE,oBAAA","sourcesContent":[".retriesNumberSelect {\n  display: flex;\n  flex-flow: row;\n  justify-content: flex-end;\n  align-items: center;\n  & p {\n    margin-right: 8px;\n  }\n}\n\n.retriesSelector {\n  width: 4.5em;\n}\n\n.serviceSearchTitle {\n  margin-bottom: 1em;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.serviceSearchTitleDisabled {\n  color: rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retriesNumberSelect": `eTe2BpWFY2plWLYQM0vR`,
	"retriesSelector": `_bxFo6abZ3WZKTYKwVYo`,
	"serviceSearchTitle": `m944tv8DkFEZkfmJlU0T`,
	"serviceSearchTitleDisabled": `CQrUufYyzBkguQsXXIt1`
};
export default ___CSS_LOADER_EXPORT___;
