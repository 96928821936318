import { useEffect, useState } from "react"

const getWindowSize = () => ({ width: window.innerWidth, height: window.innerHeight })

export function useWindowSize() {
  const [size, setSize] = useState(getWindowSize())

  useEffect(() => {
    const handleResize = () => setSize(getWindowSize())
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return size
}
