// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gakvp3k4XCxxS7opC5gd{display:flex;flex:1;flex-direction:column}.pGSfH_DewQnw9DPuVltD{display:flex;flex:1;flex-direction:column;align-items:flex-end}.Ic2B8TiUMae5oQIfYEVx{padding-bottom:9px;width:100%;text-align:left}.DyfLB3g2jMX3DZZ6HYQi{width:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/QuestionEditor/components/PromptSettings/PhoneNumber/PhoneNumber.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,MAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,MAAA,CACA,qBAAA,CACA,oBAAA,CAGF,sBACE,kBAAA,CACA,UAAA,CACA,eAAA,CAGF,sBACE,UAAA","sourcesContent":[".phoneNumberInputContainer {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n\n.checkboxContainer {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.settingsTitle {\n  padding-bottom: 9px;\n  width: 100%;\n  text-align: left;\n}\n\n.textField {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneNumberInputContainer": `gakvp3k4XCxxS7opC5gd`,
	"checkboxContainer": `pGSfH_DewQnw9DPuVltD`,
	"settingsTitle": `Ic2B8TiUMae5oQIfYEVx`,
	"textField": `DyfLB3g2jMX3DZZ6HYQi`
};
export default ___CSS_LOADER_EXPORT___;
