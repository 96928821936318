// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FdR_sHUrwzb58fEjSbXI{padding-bottom:9px;width:100%;text-align:left}.Rw6yKQqNs2UX2vx7tR11{padding:8px 0}.NvVMo1wsw5abuvFNihXz{pointer-events:none;opacity:.3}.nUQ9thPYHWTJ8krm6ivK{display:flex;align-items:center;width:100%}.M4pKGSfTpZGgLkpzH2G1{height:36px;flex:1;border-radius:15px;margin:12px 6px;border:1px solid #d3d3d3;padding:8px 16px}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CollectUSAddress/CollectUSAddress.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,UAAA,CACA,eAAA,CAGF,sBACE,aAAA,CAGF,sBACE,mBAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CAGF,sBACE,WAAA,CACA,MAAA,CACA,kBAAA,CACA,eAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".settingsTitle {\n  padding-bottom: 9px;\n  width: 100%;\n  text-align: left;\n}\n\n.messagesContainer {\n  padding: 8px 0;\n}\n\n.messagesContainerDisabled {\n  pointer-events: none;\n  opacity: 0.3;\n}\n\n.keyInputContainer {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.keyInput {\n  height: 36px;\n  flex: 1;\n  border-radius: 15px;\n  margin: 12px 6px;\n  border: 1px solid lightgray;\n  padding: 8px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsTitle": `FdR_sHUrwzb58fEjSbXI`,
	"messagesContainer": `Rw6yKQqNs2UX2vx7tR11`,
	"messagesContainerDisabled": `NvVMo1wsw5abuvFNihXz`,
	"keyInputContainer": `nUQ9thPYHWTJ8krm6ivK`,
	"keyInput": `M4pKGSfTpZGgLkpzH2G1`
};
export default ___CSS_LOADER_EXPORT___;
