import { action, makeObservable, observable } from "mobx"
import {
  Actions,
  EditorType,
  IActionEditorState,
  IConditionEditorState,
  IQuestionEditorState,
  PromptType
} from "../components/FlowEditor/models/NodeEditors"
import {
  ChatFlowsEnum,
  IDefaultChatFlowNodeSettings,
  DiscussionSteps,
  KeyType,
  IChatbotPropertyKey,
  IAdvancedCondition,
  LeftHandContextType,
  RightHandContextType,
  TextOperators,
  IIneligibleUserSettings,
  IneligibilityReason
} from "@limbic/types"

const DEFAULT_COUNTRY_CODE = process.env.REACT_APP_DEFAULT_PHONE_NUMBER_REGION_CODE

const QUESTION_DATA: IQuestionEditorState = {
  promptType: PromptType.Name,
  currentMessage: "",
  messages: [],
  questionName: "",
  promptSettings: {
    checkboxOptions: [
      { body: "Yes", initialValue: false },
      { body: "No", initialValue: false }
    ],
    options: ["Yes", "No"],
    optionsToSelectIndividually: [],
    isUndoable: true,
    trackResponse: false,
    forceValue: false,
    crisisDetection: true,
    setPeople: false,
    textPromptWithInlineOption: false,
    textPromptPlaceholder: "Please specify",
    textPromptForceValue: false,
    phonePromptPlaceholder: "Please type phone number"
  }
}

const CHAT_FLOW_DATA: IDefaultChatFlowNodeSettings = {
  chatFlow: ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP,
  chatFlowSettings: {
    choicesMap: [],
    options: [],
    optionsToSelectIndividually: [],
    currentMessage: {
      askPhoneNumber: "",
      askCanIContactYouOnPhoneNumber: "",
      askDoYouWantToShareEmail: "",
      askEmail: "",
      askEmailPermission: "",
      askLongTermMedicalCondition: "",
      askLongTermMedicalConditionOther: "",
      askSexuality: "",
      askPreferredContactMethod: "",
      askNHSNumber: "",
      askMainIssue: "",
      askUSAddress: "",
      askPermissionToMail: "",
      closingMessage: "",
      askDisabilityStatus: "",
      askDisability: "",
      askDisabilityOther: "",
      askPrimaryLanguage: "",
      askRequiresAnInterpreter: "",
      askInterpreterLanguage: "",
      askAbleToCommunicateInEnglish: "",
      askGender: "",
      askSameGenderAsBirth: "",
      askHasADHD: "",
      askHasASD: "",
      askWhatIsYourGoal: "",
      askPriorMHTreatment: "",
      sayReferralFailed: "",
      sayReferralSucceeded: "",
      askReligion: "",
      askPostCodeOfUser: "",
      askWantMeToReferYouAnyway: "",
      askWantMeToReferYouAnywaySpineSearch: "",
      askYourAreaOrGPPostCode: "",
      askPostCodeOfUserSpine: "",
      sayIntroToSpineSearch: "",
      saySearchSuccessful: "",
      sayICouldntFindYourGP: "",
      sayCannotReferYou: "",
      askAlcohol: "",
      askAlcoholFrequency: "",
      askAlcoholQuantity: "",
      askAlcoholImpactsLife: "",
      askSubstances: "",
      askSubstancesForMood: "",
      askSubstancesInfo: "",
      askSubstancesImpactLife: "",
      askNonPrescribedSubstances: "",
      askSubstancesAreMedication: "",
      askPrescribedMedication: "",
      askMedicationInfo: "",
      askMedicationWithinDosage: "",
      askMedicationNotTaking: "",
      askMedicationNotTakingInfo: "",
      askBirthday: "",
      askFullName: "",
      sayPleaseGiveFullName: "",
      sayNiceToMeetYou: "",
      askIsPreferredName: "",
      sayPleaseGivePreferredName: "",
      sayTriggerWordsDetected: "",
      askAreYouInCrisis: "",
      saySorryForTheMisunderstanding: "",
      saySorryToHear: "",
      sayCrisisNumbers: "",
      recapMessage: "",
      showTOSLinks: "",
      askResearchConsent: "",
      sayReady: "",
      askCanYouKeepYourselfSafe: "",
      sayCrisisRiskPathway: "",
      sayCrisisNumbersRiskPathway: "",
      sayLetsCarryOn: "",
      conditionalRecapMessage0: "",
      conditionalRecapMessage1: "",
      conditionalRecapMessage2: "",
      conditionalRecapMessage3: "",
      conditionalRecapMessage4: "",
      conditionalRecapMessage5: "",
      conditionalRecapMessage6: "",
      conditionalRecapMessage7: "",
      conditionalRecapMessage8: "",
      conditionalRecapMessage9: "",
      conditionalRecapMessage10: "",
      conditionalRecapMessage11: "",
      conditionalRecapMessage12: "",
      conditionalRecapMessage13: "",
      conditionalRecapMessage14: "",
      conditionalRecapMessage15: "",
      conditionalRecapMessage16: "",
      conditionalRecapMessage17: "",
      conditionalRecapMessage18: "",
      conditionalRecapMessage19: "",
      askFeedback: "",
      saySorryToHearThat: "",
      sayUnderstood: "",
      sayImGlad: "",
      sayBookAppointmentIntro: "",
      sayBookAppointmentSubmitReferralFailed: "",
      sayBookAppointmentGetSlotsFailed: "",
      askBookAppointmentSelectSlot: "",
      sayBookAppointmentConfirmSlot: "",
      sayBookAppointmentSlotUnavailable: "",
      sayBookAppointmentBookingError: "",
      sayBookAppointmentBookedSuccessfully: "",
      sayBookAppointmentGoodbyeLeave: "",
      smiIntroMessage: ""
    },
    messages: {
      askPhoneNumber: ["What's the best phone number to reach you on?"],
      askCanIContactYouOnPhoneNumber: [
        "And is it ok to send you text messages or leave a voicemail if I can't get through?"
      ],
      askDoYouWantToShareEmail: ["Would you also like to provide an email address?"],
      askEmail: ["Please type your email address"],
      askEmailPermission: ["Do you allow us to contact you over email?"],
      askPreferredContactMethod: ["What is your preferred method for correspondence?"],
      askGender: ["Which gender do you identify as?"],
      askSameGenderAsBirth: [
        "Thanks {name}, and is your gender the same as the gender you were assigned at birth?" // eslint-disable-line
      ],
      askSexuality: ["How would you describe your sexuality?"],
      askDisabilityStatus: ["Do you have a disability?"],
      askDisability: ["Okay, please specify"],
      askDisabilityOther: ['I see you selected "Other"', "Please specify"],
      askNHSNumber: ["What is your NHS number?"],
      askMainIssue: [
        "So {name}, what's the main issue that has brought you here today?",
        "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life)"
      ],
      closingMessage: ["Thank you for sharing {name}, you've come to the right place"],
      askPrimaryLanguage: ["What is your primary spoken language?"],
      askRequiresAnInterpreter: ["Do you require an interpreter?"],
      askInterpreterLanguage: ["Yes, I need an interpreter for {primaryLanguage}"],
      askAbleToCommunicateInEnglish: ["Are you able to communicate in English?"],
      askLongTermMedicalCondition: ["Do you have a long term medical condition?"],
      askLongTermMedicalConditionOther: ["Please specify"],
      askHasADHD: [
        "Do you have a confirmed diagnosis of Attention Deficit Hyperactivity Disorder (ADHD)?"
      ],
      askHasASD: ["Do you have a confirmed diagnosis of Autistic Spectrum Disorder (ASD)?"],
      askWhatIsYourGoal: [
        "By seeking support what are you hoping will change for you?",
        "For example, being able to engage in activities you are not currently able to do, or get back to doing things you enjoy"
      ],
      askPriorMHTreatment: [
        "Have you had any prior treatment for your mental health?",
        "If yes, please give me some details"
      ],
      askReligion: ["What is your religion?"],
      sayReferralFailed: [
        "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data to {iaptName}",
        "I've notified my creators of this issue",
        "If you don't wish to wait, you can manually refer yourself by following this link [here]{url})"
      ],
      sayReferralSucceeded: [
        "And that's everything",
        "You've officially been referred to {iaptName}",
        "Congratulations on taking this important step towards better mental health!"
      ],
      askPostCodeOfUser: ["Please type your postcode below"],
      askWantMeToReferYouAnyway: [
        "But that's okay 😊",
        "I can still refer you to {organisationName}",
        "Would you like me to do that for you?"
      ],
      askWantMeToReferYouAnywaySpineSearch: [
        "But that's okay 😊",
        "I can still refer you to {organisationName}",
        "Would you like me to do that for you?"
      ],
      askYourAreaOrGPPostCode: [
        "So {name}, in order to find the right health service for you, I first need to locate your registered GP",
        "If you want to give me your post code, I can look for GP clinics in your area",
        "Alternatively, if you know the name/address of your GP clinic, we can go from there",
        "Which would you prefer?"
      ],
      askPostCodeOfUserSpine: ["Please type your postcode below"],
      askUSAddress: ["What's your address?"],
      askPermissionToMail: [
        "Are you happy for us to send you written communications to your home?"
      ],
      sayIntroToSpineSearch: [
        "Alright, I'm just going to search you in the NHS database with the details you've given me"
      ],
      saySearchSuccessful: ["Found you!"],
      sayICouldntFindYourGP: [
        "Found you!",
        "Hmm, however it looks like I wasn't able to find your GP",
        "Let me see if I can find your GP in an other way"
      ],
      sayCannotReferYou: [
        "Hmmmm... I haven't been able to find your GP {name}",
        "It's important that we identify your GP in order to find the right mental health service for you",
        "Without it, I cannot refer you to {organisationName}",
        "I'm just a humble robot. My only goal is to help you. Sorry I wasn't able to do that on this occasion",
        "If you feel this is an error and you should be eligible for the service, please call us on {organisationGenericPhoneNumber}"
      ],
      askAlcohol: ["Are you currently using alcohol to manage your mood?"],
      askAlcoholFrequency: ["How often do you have a drink containing alcohol?"],
      askAlcoholQuantity: [
        "Ok, now take a look at the following image:",
        {
          type: "imageAttachment",
          image:
            "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/alcohol-units.png",
          imageDescription:
            "There is 1 unit of alcohol in a 250ml glass of beer or a 75ml glass of wine or a 25ml glass of spirit"
        },
        "How many units would you have on a typical day when you are drinking alcohol?"
      ],
      askAlcoholImpactsLife: [
        "Does drinking alcohol have an impact on your home life i.e., work, studies, family life?"
      ],
      askSubstances: ["Do you currently use any recreational drugs?"],
      askSubstancesForMood: ["Are you currently using any recreational drugs to manage your mood?"],
      askSubstancesInfo: [
        "Could you please describe what your use is, and how often do you use them?"
      ],
      askSubstancesImpactLife: [
        "Do these substances have an impact on your home life i.e., work, studies, family life?"
      ],
      askNonPrescribedSubstances: [
        "Have you taken any other non-prescribed substances to manage your mood?"
      ],
      askSubstancesAreMedication: [
        "Are these medications bought over the counter at a pharmacy or supermarket?"
      ],
      askPrescribedMedication: [
        "Are you currently taking any medication that has been prescribed by a doctor?"
      ],
      askMedicationInfo: [
        "Could you let me know the details of the medication you've been prescribed?"
      ],
      askMedicationWithinDosage: [
        "Are you using the medication within the recommended dose range on the packet?"
      ],
      askMedicationNotTaking: [
        "Okay, and just to check, have you been prescribed any medication that you are not taking?"
      ],
      askMedicationNotTakingInfo: [
        "Please let me know the details of the medication you've been prescribed but are not taking"
      ],
      askBirthday: ["First off, what's your date of birth?"],
      askFullName: ["What's your full name? ✏️"],
      sayPleaseGiveFullName: ["Please enter your full name"],
      sayNiceToMeetYou: ["Nice to meet you {name}"],
      askIsPreferredName: ["Is {name} your preferred first name?"],
      sayPleaseGivePreferredName: ["What would you like to be called?"],
      sayTriggerWordsDetected: ["I noticed that you wrote the words {trigger}"],
      askAreYouInCrisis: [
        "This has made me think you might be at immediate risk of harm or in a life threatening urgent situation",
        "Is this true?"
      ],
      saySorryForTheMisunderstanding: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      saySorryToHear: [
        "Sorry to hear that {name}",
        "However {organisationName} does not provide urgent care"
      ],
      sayCrisisNumbers: [
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "Other helplines available:\n-You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email\n-If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help"
      ],
      recapMessage: [
        "To recap:\nWe'll continue with the referral, however {organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above"
      ],
      showTOSLinks: [
        "To get you the best referral, I will need to share your answers with {organisationName}",
        "I want to reassure you that your details will be stored safely and kept confidential"
      ],
      askResearchConsent: [
        "I am also working with researchers to improve mental health treatment",
        "Are you ok if Limbic uses your data anonymously to support the development of the product and research, which might be used for scientific publications?",
        "Your answer will not impact the decision on whether or not we can provide you with this service"
      ],
      sayReady: ["Let's continue", "Ready?"],
      askCanYouKeepYourselfSafe: [
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 2 weeks)?"
      ],
      sayCrisisRiskPathway: [
        "Thank you for sharing this information",
        "However {organisationName} does not provide urgent care"
      ],
      sayCrisisNumbersRiskPathway: [
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "Other helplines available:\nYou can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\nIf you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help"
      ],
      sayLetsCarryOn: ["Ok, let's carry on with the mental health check in"],
      conditionalRecapMessage0: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage1: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage2: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage3: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage4: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage5: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage6: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage7: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage8: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage9: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage10: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage11: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage12: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage13: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage14: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage15: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage16: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage17: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage18: ["Well, it's been a pleasure getting to know you {name}"],
      conditionalRecapMessage19: ["Well, it's been a pleasure getting to know you {name}"],
      askFeedback: ["I hope I've been able to help you today"],
      saySorryToHearThat: [
        "Sorry to hear that. How could I improve?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ],
      sayUnderstood: [
        "Understood. How could I improve?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ],
      sayImGlad: [
        "I'm really glad to hear that",
        "What was the main benefit I was able to bring you?",
        "(Please Note: Feedback is audited and used to inform service improvement however, entries given here are not reviewed or responded to by a trained clinician)"
      ],
      sayBookAppointmentIntro: [
        "I'm going to put you in touch with a qualified mental health professional"
      ],
      sayBookAppointmentSubmitReferralFailed: [
        "Sorry, we're encountering a persistent issue and can't continue with the appointment booking",
        "Someone from the service team will call you to organise your appointment"
      ],
      sayBookAppointmentGetSlotsFailed: [
        "Hmmm... something went wrong while fetching your appointment slots",
        "Someone from the service team will call you to organise your appointment"
      ],
      askBookAppointmentSelectSlot: [
        "Let's see all the available appointment slots",
        "If you can't find an appointment slot that suits you, please click \"continue\" and you'll be added to our waitlist. A local practitioner will be in touch to arrange one with you",
        "Please use the arrows either side of the dates to view more available options"
      ],

      sayBookAppointmentConfirmSlot: [
        "You have selected the following date and time",
        `{slotDate}\n{slotStartTime} - {slotEndTime}`,
        "Is this correct?"
      ],
      sayBookAppointmentSlotUnavailable: [
        "Hmmm... something went wrong while booking your appointment",
        "Please try again"
      ],
      sayBookAppointmentBookingError: [
        "Sorry {name}, we're encountering a persistent issue when trying to confirm your appointment booking",
        "So we'll ask one of the {serviceName} team to give you a call to organise your appointment"
      ],
      sayBookAppointmentBookedSuccessfully: [
        "Great {name}, your appointment has been booked successfully"
      ],
      sayBookAppointmentGoodbyeLeave: ["Okay"],
      smiIntroMessage: [
        "The next part is some quick questions which cover symptoms of more severe mental health issues. They might not relate to how you are feeling, but it is important that you answer these honestly so that we can help you get the right support for how you are feeling"
      ]
    },
    [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
      requestSMSConsent: true,
      requestVoicemailConsent: true,
      supportedCountries: [DEFAULT_COUNTRY_CODE].filter(Boolean) as string[]
    },
    [ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS]: {
      includeOther: true,
      shouldAskOtherDetails: true,
      shouldAskDoesLTCAffectsMood: true,
      shouldAskHowMuchLTCAffectsMood: true,
      shouldAskHowWellYouManageYourLTC: true
    },
    [ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]: {
      shouldAskAlcoholFrequency: true,
      shouldAskAlcoholQuantity: true,
      shouldAskAlcoholImpactsLife: true
    },
    [ChatFlowsEnum.COLLECT_SUBSTANCES]: {
      shouldAskSubstances: true,
      shouldAskSubstancesForMood: false,
      shouldAskSubstancesInfo: false,
      shouldAskSubstancesImpactLife: false,
      shouldAskNonPrescribedSubstances: true,
      shouldAskSubstancesAreMedication: true,
      shouldAskPrescribedMedication: true,
      shouldAskMedicationInfo: true,
      shouldAskMedicationWithinDosage: false,
      shouldAskMedicationNotTaking: false,
      shouldAskMedicationNotTakingInfo: false
    },
    [ChatFlowsEnum.COLLECT_NAME]: {
      shouldAskPreferredName: true,
      shouldSayNiceToMeetYou: true
    },
    [ChatFlowsEnum.GET_PERMISSIONS]: {
      shouldAskResearchConsent: true,
      shouldSayReady: true
    },
    [ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS]: {
      enableADSM: true,
      shouldSendRiskEmail: false,
      shouldRiskSendEmailEvenIfCanKeepSafe: true
    },
    [ChatFlowsEnum.COLLECT_FEEDBACK]: {
      shouldAskAdditionalFeedback: true
    },
    [ChatFlowsEnum.COLLECT_PREFERRED_CORRESPONDENCE]: {
      shouldDisplayTextMessageOption: true
    },
    [ChatFlowsEnum.SPINE_SEARCH]: {
      canReferWithoutGP: false,
      shouldUseServiceSearchFallback: true
    },
    [ChatFlowsEnum.BOOK_APPOINTMENT]: {},
    [ChatFlowsEnum.COLLECT_SMI]: {
      shouldUpdateReferral: true
    },
    [ChatFlowsEnum.COLLECT_US_ADDRESS]: {
      shouldAskPermissionToMail: true
    }
  }
}

const CONDITION_DATA: IConditionEditorState = {
  inputs: [],
  ifStatement: {
    input: "",
    action: "exists"
  },
  thenBlock: {
    nextStep: ""
  },
  elseBlock: {
    nextStep: ""
  }
}

export const VALUE_NOT_SET = "-"

export const OPERAND_FALLBACK_VALUE: IChatbotPropertyKey = {
  type: KeyType.Text,
  context: VALUE_NOT_SET,
  sourceKey: VALUE_NOT_SET
}

export type IAdvancedConditionEditorState = IAdvancedCondition

const ADVANCED_CONDITION_DATA: IAdvancedConditionEditorState = {
  leftOperand: {
    storageType: LeftHandContextType.STATE,
    value: OPERAND_FALLBACK_VALUE,
    transformers: []
  },
  operator: TextOperators.CONTAINS,
  rightOperand: {
    storageType: RightHandContextType.STATE,
    value: OPERAND_FALLBACK_VALUE,
    transformers: []
  }
}

const INELIGIBLE_USER_DATA: IIneligibleUserSettings = {
  reason: IneligibilityReason.OUT_OF_AREA,
  shouldUpdateDatabase: true,
  shouldTrackMixpanel: true
}

const ACTION_DATA: IActionEditorState = {
  action: Actions.TRACK,
  actionStateKey: "",
  actionStateValueType: "boolean",
  actionStateValue: "",
  nextDialogue: DiscussionSteps.Goodbye,
  actionUpdateReferralWithKeys: [],
  actionAddClinicalNotes: [],
  actionAddClinicalNotesShouldUpdateReferral: true,
  actionSetRiskLevel: "HIGH",
  actionSetRiskLevelIsCrisis: false,
  setIAPTid: "",
  setIAPTName: "",
  actionLanguageToSet: "browserLanguage"
}

export const DEFAULT_SETTINGS = {
  [EditorType.QUESTION]: QUESTION_DATA,
  [EditorType.CHAT_FLOW]: CHAT_FLOW_DATA,
  [EditorType.CONDITION]: CONDITION_DATA,
  [EditorType.ADVANCED_CONDITION]: ADVANCED_CONDITION_DATA,
  [EditorType.INELIGIBLE_USER]: INELIGIBLE_USER_DATA,
  [EditorType.ACTION]: ACTION_DATA
}

export class NodeEditorStore {
  @observable nodeId: string
  @observable questionEditorOpen: boolean
  @observable conditionEditorOpen: boolean
  @observable advancedConditionEditorOpen: boolean
  @observable actionEditorOpen: boolean
  @observable ineligibleUserEditorOpen: boolean
  @observable chatFlowEditorOpen: boolean
  @observable highLevelFlowEditorOpen: boolean
  @observable editorOpen?: EditorType | undefined
  @observable actionEditorState: IActionEditorState
  @observable chatFlowEditorState: IDefaultChatFlowNodeSettings
  @observable ineligibleUserEditorState: IIneligibleUserSettings
  @observable conditionEditorState: IConditionEditorState
  @observable advancedConditionEditorState: IAdvancedConditionEditorState
  @observable questionEditorState: IQuestionEditorState
  @observable isSaveButtonDisabled: { disabled: boolean; disabledReason: string | undefined }

  constructor() {
    this.nodeId = ""
    this.questionEditorOpen = false
    this.conditionEditorOpen = false
    this.advancedConditionEditorOpen = false
    this.ineligibleUserEditorOpen = false
    this.ineligibleUserEditorState = DEFAULT_SETTINGS[EditorType.INELIGIBLE_USER]
    this.actionEditorOpen = false
    this.chatFlowEditorOpen = false
    this.highLevelFlowEditorOpen = false
    this.actionEditorState = DEFAULT_SETTINGS[EditorType.ACTION]
    this.chatFlowEditorState = DEFAULT_SETTINGS[EditorType.CHAT_FLOW]
    this.conditionEditorState = DEFAULT_SETTINGS[EditorType.CONDITION]
    this.advancedConditionEditorState = DEFAULT_SETTINGS[EditorType.ADVANCED_CONDITION]
    this.questionEditorState = DEFAULT_SETTINGS[EditorType.QUESTION]
    this.isSaveButtonDisabled = { disabled: false, disabledReason: undefined }
    makeObservable(this)
  }

  /** Actions */

  @action
  setEditorOpen(editor: EditorType, id): void {
    const key = `${editor}EditorOpen`
    this[key] = true
    this.nodeId = id
  }

  @action
  setEditorClosed(editor: EditorType): void {
    const key = `${editor}EditorOpen`
    this[key] = false
  }

  @action
  setHighLevelEditorOpen(): void {
    this.highLevelFlowEditorOpen = true
  }

  @action
  setHighLevelEditorClosed(): void {
    this.highLevelFlowEditorOpen = false
  }

  @action
  resetState(editor: EditorType): void {
    const key = `${editor}EditorState`
    this[key] = DEFAULT_SETTINGS[editor]
  }

  @action
  updateState(editor: EditorType, data: any): void {
    const key = `${editor}EditorState`
    const currentState = this[key]
    this[key] = { ...currentState, ...data }
  }

  @action
  updateChatFlowState(data: any): void {
    const editor = EditorType.CHAT_FLOW
    this.updateState(editor, data)
  }

  setState(editor: EditorType, data: any): void {
    const key = `${editor}EditorState`
    this[key] = { ...data }
  }

  @action
  setSaveButtonDisabilityStatus(value: boolean, reason?: string): void {
    this.isSaveButtonDisabled = {
      disabled: value,
      disabledReason: reason
    }
  }
}
