// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ozaxDh1qAnnLzvvWjfsr{display:flex;flex-direction:row;justify-content:flex-start;gap:10px;position:relative}.uNPujYQSU19Itd96PTwX{display:flex;flex-direction:column;align-self:flex-end;position:relative;z-index:1;margin-top:-20px;justify-content:space-evenly;height:150px}.uNPujYQSU19Itd96PTwX::before{content:"";position:absolute;top:0px;left:-10px;right:-10px;bottom:0px;background:#282c34;border-radius:0px 20px 20px 0px;box-shadow:inset 0 4px 6px rgba(0,0,0,.1);z-index:-1}.CNKzfp3PN7NSFP6gZ4WQ>svg{fill:#fff}.CNKzfp3PN7NSFP6gZ4WQ>svg:hover{fill:#fd81a0}.CNKzfp3PN7NSFP6gZ4WQ:hover{transform:scale(1.1);box-shadow:0 6px 12px rgba(0,0,0,.15)}`, "",{"version":3,"sources":["webpack://./src/app/components/SnippetItem/SnippetItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,QAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,iBAAA,CACA,SAAA,CACA,gBAAA,CACA,4BAAA,CACA,YAAA,CAGF,8BACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,+BAAA,CACA,yCAAA,CACA,UAAA,CAIA,0BACE,SAAA,CACA,gCACE,YAAA,CAKN,4BACE,oBAAA,CACA,qCAAA","sourcesContent":[".snippetItem {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  gap: 10px;\n  position: relative;\n}\n\n.snippetActions {\n  display: flex;\n  flex-direction: column;\n  align-self: flex-end;\n  position: relative;\n  z-index: 1;\n  margin-top: -20px;\n  justify-content: space-evenly;\n  height: 150px;\n}\n\n.snippetActions::before {\n  content: \"\";\n  position: absolute;\n  top: 0px;\n  left: -10px;\n  right: -10px;\n  bottom: 0px;\n  background: #282c34;\n  border-radius: 0px 20px 20px 0px;\n  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1);\n  z-index: -1;\n}\n\n.snippetActionsButton {\n  >svg {\n    fill: #fff;\n    &:hover {\n      fill: #fd81a0;\n    }\n  }\n}\n\n.snippetActionsButton:hover {\n  transform: scale(1.1);\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snippetItem": `ozaxDh1qAnnLzvvWjfsr`,
	"snippetActions": `uNPujYQSU19Itd96PTwX`,
	"snippetActionsButton": `CNKzfp3PN7NSFP6gZ4WQ`
};
export default ___CSS_LOADER_EXPORT___;
