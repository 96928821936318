import React, { useCallback } from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { ChatFlowsEnum } from "@limbic/types"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"
import SolidDivider from "../../../../../SolidDivider"
import { Typography } from "@mui/material"
import styles from "./CollectUSAddress.module.scss"
import FormControlWithCheckboxControlledInput from "../FormControlWithCheckBox/FormControlWithCheckboxControlledInput"
import clsx from "classnames"

const CollectUSAddress = () => {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages
  const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_US_ADDRESS]
  const useCustomKeys = !!currentSettings?.usAddressKey

  const updateState = useCallback(
    data => nodeEditorStore.updateChatFlowState(data),
    [nodeEditorStore]
  )

  const onCustomiseStateKeysClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_US_ADDRESS] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_US_ADDRESS]: { ...currentSettings }
    }

    // if value is true it means we're enabling custom keys
    // so, we need to reset the entries to the default values
    // otherwise remove the entries entirely
    if (value) {
      newChatFlowSettings[ChatFlowsEnum.COLLECT_US_ADDRESS] = {
        ...currentSettings,
        usAddressKey: "addressHome"
      }
    } else {
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_US_ADDRESS].usAddressKey
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const updateChatFlowSettings = useCallback(
    newSettings => {
      const newChatFlowSettings = {
        ...chatFlowSettings,
        [ChatFlowsEnum.COLLECT_US_ADDRESS]: {
          ...currentSettings,
          ...newSettings
        }
      }
      updateState({ chatFlowSettings: newChatFlowSettings })
    },
    [chatFlowSettings, currentSettings, updateState]
  )

  const onEditUSAddressKey = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateChatFlowSettings({ usAddressKey: event.target.value || undefined })
    },
    [updateChatFlowSettings]
  )

  return (
    <>
      <Typography className={styles.settingsTitle}>Collect Address:</Typography>
      <MessageList
        messages={messages?.askUSAddress ?? []}
        messageType="askUSAddress"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askUSAddress}
        placeholder="Type a message here..."
        name="askUSAddress"
        updateState={updateState}
        messages={messages?.askUSAddress ?? []}
      />
      {useCustomKeys && (
        <div className={styles.keyInputContainer}>
          <Typography>State Key</Typography>
          <input
            className={styles.keyInput}
            value={currentSettings?.usAddressKey ?? "addressHome"}
            placeholder="address"
            onChange={onEditUSAddressKey}
          />
        </div>
      )}
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldAskPermissionToMail"
        label="Ask for permission to send mail to the user's address"
        labelPlacement="start"
        updateState={updateState}
      />
      <div
        className={clsx(styles.messagesContainer, {
          [styles.messagesContainerDisabled]: !currentSettings?.shouldAskPermissionToMail
        })}>
        <MessageList
          messages={messages?.askPermissionToMail ?? []}
          messageType="askPermissionToMail"
          chatFlowSettings={chatFlowSettings}
          updateState={updateState}
        />
        <MessageForm
          value={chatFlowSettings.currentMessage?.askPermissionToMail}
          placeholder="Type a message here..."
          name="askPermissionToMail"
          updateState={updateState}
          messages={messages?.askPermissionToMail ?? []}
        />
      </div>
      <SolidDivider />
      <Typography className={styles.settingsTitle}>Advanced Settings:</Typography>
      <FormControlWithCheckboxControlledInput
        name="customiseStateKeys"
        value={useCustomKeys}
        label="Edit state keys"
        labelPlacement="start"
        onToggle={onCustomiseStateKeysClick}
      />
    </>
  )
}

export default observer(CollectUSAddress)
