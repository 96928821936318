import { action, computed, makeObservable, observable } from "mobx"
import invariant from "../../utils/invariant"
import { getServiceWithVersion } from "../../backend/api/service"
import { ServiceStatus } from "../../models/Service"
import {
  BasicConfiguration,
  DiscussionSteps,
  DiscussionsStepsList,
  IClinicalPath,
  ICustomClinicalPathsDescriptor,
  IDashboardDoc,
  IDialogueDashboard,
  IKeyTransformMap,
  KeepingSafeEmailUpload,
  BackendIntegration,
  IServiceExtendedConfig,
  EligibilityConfigurationDashboard,
  BigBotConfiguration,
  LanguageCodes,
  Translation
} from "@limbic/types"
import updateConfiguration from "../../backend/api/configuration"
import updateEligibilityConfiguration from "../../backend/api/eligibilityConfiguration"
import { updateClinicalPaths, updateCustomClinicalPaths } from "../../backend/api/clinicalPaths"
import updateHighLevelFlow from "../../backend/api/updateHighLevelFlow"
import updateSingleFlow from "../../backend/api/updateFlows"
import { updateKeepingSafeEmail } from "../../backend/api/keepingSafeEmail"
import publish from "../../backend/api/publish"
import { updateBackendMapping } from "../../backend/api/mapping"
import updateIntegration from "../../backend/api/integration"
import { getAvailableServices } from "../../backend/api/services"
import updateBigBotConfiguration from "../../backend/api/bigBotConfiguration"
import updateLanguages from "../../backend/api/languages"
import updateTranslations from "../../backend/api/translations"

export class ServiceStore {
  @observable serviceKey?: string
  @observable services?: IServiceExtendedConfig[]
  @observable isLoading!: boolean
  @observable mode!: "draft" | "published"
  @observable serviceData?: IDashboardDoc

  async onGetServiceData?(serviceData: this["serviceData"]): Promise<void>

  constructor() {
    this.reset()
    makeObservable(this)
  }

  /** Actions */

  @action
  reset(): void {
    this.isLoading = false
    this.mode = "draft"
  }

  @action
  setServiceKey(serviceKey?: string): void {
    this.serviceKey = serviceKey
  }

  @action
  setServices(services?: IServiceExtendedConfig[]): void {
    this.services = services?.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    )
  }

  @action
  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  @action
  setMode(mode: "draft" | "published"): void {
    this.mode = mode
  }

  @action
  setServiceData(data?: IDashboardDoc): void {
    this.serviceData = data
  }

  @action
  async getServices(): Promise<ServiceStatus> {
    const [services, getServicesStatus] = await getAvailableServices()
    if (getServicesStatus === ServiceStatus.Success) {
      this.setServices(
        services?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      )
      return ServiceStatus.Success
    } else {
      this.setServices([])
      return ServiceStatus.RequestFailed
    }
  }

  @action
  async getServiceData(): Promise<ServiceStatus> {
    try {
      invariant(this.serviceKey, "Cannot get data without a serviceApiKey")
      this.setIsLoading(true)
      const [data, status] = await getServiceWithVersion(this.serviceKey, this.mode)
      if (status === ServiceStatus.Success) {
        this.setServiceData(data)
        await this.onGetServiceData?.(data)
      }
      return status
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateServiceConfiguration(config: BasicConfiguration, logo?: File): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateConfiguration(this.serviceKey, config!, logo)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateServiceEligibilityConfiguration(
    config: EligibilityConfigurationDashboard
  ): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateEligibilityConfiguration(this.serviceKey, config!)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateBigBotConfiguration(
    config: BigBotConfiguration,
    shouldRemoveIcon: boolean,
    image?: File
  ): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      if (shouldRemoveIcon) {
        delete config.sidebarIAPTIcon
      }
      await updateBigBotConfiguration(this.serviceKey, config!, image)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateTranslationLanguages(
    defaultLanguage: LanguageCodes,
    supportedLanguages: LanguageCodes[] | undefined
  ): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateLanguages(this.serviceKey, defaultLanguage, supportedLanguages)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateCustomTranslations(customTranslations: Record<string, Translation>): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateTranslations(this.serviceKey, customTranslations)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateServiceIntegration(config: BackendIntegration): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateIntegration(this.serviceKey, config!)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateDefaultClinicalPathsConfiguration(paths: IClinicalPath[]): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateClinicalPaths(this.serviceKey, paths)
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateCustomClinicalPathsConfiguration(
    paths: ICustomClinicalPathsDescriptor[]
  ): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateCustomClinicalPaths(this.serviceKey, paths)
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateKeepingSafeEmailConfiguration(
    keepingSafeEmail: KeepingSafeEmailUpload,
    logoURL: string,
    shouldSendKeepingSafeEmail: boolean | undefined,
    removeAttachment: boolean
  ): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateKeepingSafeEmail(
        this.serviceKey,
        keepingSafeEmail,
        logoURL,
        shouldSendKeepingSafeEmail ?? false,
        removeAttachment
      )
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateFlowConfiguration(
    dialogue: DiscussionSteps,
    flow: IDialogueDashboard
  ): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateSingleFlow(this.serviceKey, dialogue, flow)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateHighLevelFlowConfiguration(flow: DiscussionsStepsList): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update data without a serviceApiKey")
      this.setIsLoading(true)
      await updateHighLevelFlow(this.serviceKey, flow)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async updateBackendMapping(transformMap: IKeyTransformMap): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot update backend mapping without a serviceApiKey")
      this.setIsLoading(true)
      await updateBackendMapping(this.serviceKey, transformMap)
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  @action
  async publish(password?: string): Promise<void> {
    try {
      invariant(this.serviceKey, "Cannot publish without a serviceApiKey")
      invariant(password, "Cannot publish without a password")
      this.setIsLoading(true)
      const status = await publish(this.serviceKey, password)
      invariant(status !== ServiceStatus.InvalidPassword, "Invalid Password")
      invariant(status === ServiceStatus.Success, "Publish failed")
      await this.getServiceData()
    } finally {
      this.setIsLoading(false)
    }
  }

  /** Getters / Setters */

  @computed
  get serviceName(): string | undefined {
    return this.services?.find(service => service.serviceApiKey === this.serviceKey)?.name
  }

  @computed
  get modeConfig(): BasicConfiguration | undefined {
    return this.serviceData?.[this.mode]?.configuration ?? undefined
  }
}
