// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v7HLdyUz6hOBal_g1Hn7{display:flex;flex-flow:column;max-width:960px;margin:auto;margin-top:0;-webkit-box-align:center;align-items:center}`, "",{"version":3,"sources":["webpack://./src/app/pages/SelectService/SelectService.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":[".layout {\n  display: flex;\n  flex-flow: column;\n  max-width: 960px;\n  margin: auto;\n  margin-top: 0;\n  -webkit-box-align: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `v7HLdyUz6hOBal_g1Hn7`
};
export default ___CSS_LOADER_EXPORT___;
