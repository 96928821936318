// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VDik7tIxzZyFQ9UOaKoR{position:relative;width:90%}.wIF4syGkZvEI90XePmHc{cursor:text;resize:none;overflow-y:hidden;margin-left:36px;position:relative;width:auto;min-width:85%;max-width:95%;padding:6px 14px;border-radius:0 22px 22px 0;border-color:rgba(7,34,48,.1);background-color:#fff;white-space:pre-wrap;border-bottom-left-radius:22px;border-bottom:3px solid rgba(7,34,48,.1);text-align:left;height:auto}.iWvUB_UI0Fh6f9OWCZoA{cursor:default;color:gray}.xmthFIRYibI8xkc2hILW{position:absolute;left:0;top:0;height:36px}.kWn7nVSvkFhVDnyQalQb{filter:contrast(0.5);-webkit-filter:contrast(0.5)}`, "",{"version":3,"sources":["webpack://./src/app/components/BotPlayground/components/BotBubbleEditable/BotBubbleEditable.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,SAAA,CAGF,sBACE,WAAA,CACA,WAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,aAAA,CACA,aAAA,CACA,gBAAA,CACA,2BAAA,CACA,6BAAA,CACA,qBAAA,CACA,oBAAA,CACA,8BAAA,CACA,wCAAA,CACA,eAAA,CACA,WAAA,CAGF,sBACE,cAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CAGF,sBACE,oBAAA,CACA,4BAAA","sourcesContent":[".botBubbleContainer {\n  position: relative;\n  width: 90%;\n}\n\n.botBubbleMessage {\n  cursor: text;\n  resize: none;\n  overflow-y: hidden;\n  margin-left: 36px;\n  position: relative;\n  width: auto;\n  min-width: 85%;\n  max-width: 95%;\n  padding: 6px 14px;\n  border-radius: 0 22px 22px 0;\n  border-color: rgba(7, 34, 48, 0.1);\n  background-color: #ffffff;\n  white-space: pre-wrap;\n  border-bottom-left-radius: 22px;\n  border-bottom: 3px solid rgba(7, 34, 48, 0.1);\n  text-align: left;\n  height: auto;\n}\n\n.botBubbleMessageDisabled {\n  cursor: default;\n  color: gray;\n}\n\n.botLogo {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 36px;\n}\n\n.botLogoDisabled {\n  filter: contrast(0.5);\n  -webkit-filter: contrast(0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botBubbleContainer": `VDik7tIxzZyFQ9UOaKoR`,
	"botBubbleMessage": `wIF4syGkZvEI90XePmHc`,
	"botBubbleMessageDisabled": `iWvUB_UI0Fh6f9OWCZoA`,
	"botLogo": `xmthFIRYibI8xkc2hILW`,
	"botLogoDisabled": `kWn7nVSvkFhVDnyQalQb`
};
export default ___CSS_LOADER_EXPORT___;
