import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { useServiceConfigurationStore, useServiceStore } from "../../../../context/rootStoreContext"
import deepCompare from "../../../../../utils/deepCompare"
import classes from "clsx"
import CustomCKEditor from "../../../../components/CustomCKEditor"
import Button from "../../../../components/Button"
import styles from "./BigBotConfiguration.module.scss"
import SideBarPreview from "./components/SideBarPreview"
import Dialog from "../../../../components/Dialog"
import LogoInput from "../../../../components/LogoInput"
import CancelIcon from "@mui/icons-material/Cancel"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import ClickToAddIcon from "../../../../assets/click-to-add.png"
import { IconButton, Tooltip } from "@mui/material"

type Size = "xs" | "sm" | "md" | "lg" | "xl"
type Action = "increase" | "decrease"

interface Props {
  setError: (value: boolean) => void
}

function BigBotConfiguration(props: Props): JSX.Element {
  const serviceStore = useServiceStore()
  const serviceConfigurationStore = useServiceConfigurationStore()
  const serviceData = serviceStore.serviceData?.[serviceStore.mode]
  const uploadedImage = React.useRef<any>(null)
  const imageUploader = React.useRef<HTMLInputElement>(null)
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false)
  const [sideBarWidth, setSideBarWidth] = useState<Size>("sm")

  const [imageRemoved, setImageRemoved] = useState<boolean>(false)
  const [imageUploaded, setImageUploaded] = useState<boolean>(false)
  const [imageError, setImageError] = useState<boolean>(false)

  const [hasError, setHasError] = useState<Record<"sidebarText" | "sidebarTitle", boolean>>({
    sidebarText: false,
    sidebarTitle: false
  })

  useEffect(() => {
    if (!isPreviewOpen && sideBarWidth !== "sm") {
      setTimeout(() => {
        setSideBarWidth("sm")
      }, 250)
    }
  }, [sideBarWidth, isPreviewOpen])

  const isChanged = useMemo(() => {
    return !deepCompare(serviceData?.bigBot ?? {}, serviceConfigurationStore.bigBot)
  }, [serviceConfigurationStore.bigBot, serviceData?.bigBot])

  const shouldRemoveIcon = useMemo(() => {
    if (imageRemoved) return true
    if (!serviceConfigurationStore.bigBot?.sidebarIAPTIcon && !imageUploaded) return true
    return false
  }, [imageRemoved, imageUploaded, serviceConfigurationStore.bigBot])

  const onSubmitChanges = async () => {
    try {
      await serviceStore.updateBigBotConfiguration(
        serviceConfigurationStore.bigBot,
        shouldRemoveIcon,
        serviceConfigurationStore.sidebarIAPTIcon
      )
    } catch (e) {
      props.setError(true)
      // TODO: report exception to sentry
      console.log({ e })
    }
  }

  const onDiscardChanges = () => {
    if (isChanged) {
      serviceConfigurationStore.setBigBotSidebarText(
        serviceStore.serviceData?.[serviceStore.mode]?.bigBot?.sidebarText ?? ""
      )
      serviceConfigurationStore.setBigBotTitle(
        serviceStore.serviceData?.[serviceStore.mode]?.bigBot?.sidebarTitle ?? ""
      )
    } else {
      serviceConfigurationStore.resetBigBot()
    }
  }

  const onMainTextInputChange = (value: string | undefined) => {
    setHasError({ ...hasError, sidebarText: !value?.length })
    serviceConfigurationStore.setBigBotSidebarText(value)
  }

  const onTitleInputChange = (value: string) => {
    setHasError({ ...hasError, sidebarTitle: !value?.length })
    serviceConfigurationStore.setBigBotTitle(value ?? "Welcome")
  }

  const onIAPTIconChange = e => {
    const logo = e.target.files[0]
    if (logo) {
      setImageUploaded(true)
      setImageRemoved(false)
      setImageError(false)

      serviceConfigurationStore.setIAPTIcon(logo)

      const reader = new FileReader()
      if (uploadedImage.current) {
        uploadedImage.current.file = logo
        reader.onload = e => {
          const source = e.target?.result
          if (source) {
            uploadedImage.current.src = source
            serviceConfigurationStore.setIAPTIconURL(String(source))
          }
        }
        reader.onerror = () => {
          setImageError(true)
        }
        reader.readAsDataURL(logo)
      }
    }
  }

  const onResetIAPTIcon = () => {
    serviceConfigurationStore.setIAPTIconURL(String(ClickToAddIcon))
    serviceConfigurationStore.setIAPTIcon()
    setImageRemoved(true)
    setImageUploaded(false)
  }

  const handleResize = (currentSize: Size, action: Action): void => {
    const sizes: Size[] = ["xs", "sm", "md", "lg", "xl"]
    let currentIndex = sizes.indexOf(currentSize)

    if (action === "increase") {
      currentIndex = Math.min(sizes.length - 1, currentIndex + 1)
    } else if (action === "decrease") {
      currentIndex = Math.max(0, currentIndex - 1)
    }

    setSideBarWidth(sizes[currentIndex])
  }

  const getErrorText = (): string[] => {
    const errors: string[] = []
    if (Object.values(hasError).every(value => value === false)) {
      return []
    }
    if (hasError["sidebarText"]) errors.push("The sidebar text is required")
    if (hasError["sidebarTitle"]) errors.push("The sidebar title is required")
    return errors
  }

  const onPreview = () => {
    setIsPreviewOpen(true)
  }

  return (
    <div className={styles.bigBotConfigurationContainer}>
      <Dialog
        maxWidth={sideBarWidth}
        open={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        title="Big Bot Sidebar Preview">
        <SideBarPreview />
        <div className={styles.controlsIncreaseDecreaseWidth}>
          <Tooltip title="Press to decrease sidebar width">
            <IconButton
              size="medium"
              disabled={sideBarWidth === "xs"}
              onClick={() => handleResize(sideBarWidth, "decrease")}>
              <RemoveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Press to increase sidebar width">
            <IconButton
              size="medium"
              disabled={sideBarWidth === "xl"}
              onClick={() => handleResize(sideBarWidth, "increase")}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Dialog>
      <div className={styles.bigBotMainConfiguration}>
        <div className={styles.inputWrapper}>
          <div
            className={classes(styles.inputInstructions, {
              [styles.error]: hasError["sidebarTitle"]
            })}>
            Side Bar Title (required)
          </div>
          <input
            placeholder="Side Bar Title (required)"
            value={serviceConfigurationStore.bigBot?.sidebarTitle}
            onChange={e => onTitleInputChange(e.target.value)}
            type="text"
            className={classes(styles.textInput, styles.textInputGeneric)}
          />
        </div>

        <div className={styles.inputWrapper}>
          <div
            className={classes(styles.inputInstructions, {
              [styles.error]: hasError["sidebarText"]
            })}>
            Side Bar Text (required)
          </div>
          <CustomCKEditor
            data={serviceConfigurationStore.bigBot?.sidebarText ?? ""}
            onChange={(data?: string) => onMainTextInputChange(data)}
          />
        </div>

        <div className={styles.inputWrapper}>
          <div className={classes(styles.inputInstructions)}>
            Side Bar Organisation Icon (optional)
          </div>
          <LogoInput
            onImageError={() => {
              console.warn("error loading sidebar icon")
              setImageError(true)
            }}
            src={serviceConfigurationStore.bigBot?.sidebarIAPTIcon || ClickToAddIcon}
            onChange={onIAPTIconChange}
            onClick={() => imageUploader.current?.click()}
            imageUploader={imageUploader}
            uploadedImage={uploadedImage}
          />
          {serviceConfigurationStore.bigBot?.sidebarIAPTIcon !== ClickToAddIcon && !imageError && (
            <span className={styles.resetLogo} onClick={onResetIAPTIcon} title="Remove image">
              <CancelIcon fontSize="small" /> Reset image
            </span>
          )}
        </div>
      </div>
      <div>
        <Button
          onClick={onSubmitChanges}
          disabled={!isChanged || Object.values(hasError).some(value => value)}>
          {serviceStore.isLoading ? "Updating..." : "Save Changes"}
        </Button>
        <Button onClick={onPreview}>Preview</Button>
        <Button onClick={onDiscardChanges} disabled={!isChanged}>
          Discard Changes
        </Button>
        <div className={styles.errorsContainer}>
          {getErrorText().map(error => (
            <span key={error}>{error}</span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default observer(BigBotConfiguration)
