import { createContext, PropsWithChildren, useContext } from "react"
import { ReactFlowInstance } from "react-flow-renderer"

const ctx = createContext<{ instance?: ReactFlowInstance } | null>(null)

interface IProps {
  instance?: ReactFlowInstance
}

export function ReactFlowInstanceProvider(props: PropsWithChildren<IProps>): JSX.Element {
  const { instance, ...rest } = props
  return <ctx.Provider value={{ instance }} {...rest} />
}

export function useReactFlowInstance(): ReactFlowInstance | undefined {
  const context = useContext(ctx)
  if (!context) {
    throw new Error("cannot use useReactFlowInstance hook outside ReactFlowInstanceProvider")
  }
  return context.instance
}
