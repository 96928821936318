// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nwwWoBvsorv23dS3Ddkp{border-top:1px solid #d3d3d3;display:flex;padding:6px 0 12px 0;box-sizing:content-box;width:100vw;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/app/components/DashboardNavigation/DashboardNavigation.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CACA,YAAA,CACA,oBAAA,CACA,sBAAA,CACA,WAAA,CACA,sBAAA","sourcesContent":[".navigation {\n  border-top: 1px solid lightgray;\n  display: flex;\n  padding: 6px 0 12px 0;\n  box-sizing: content-box;\n  width: 100vw;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `nwwWoBvsorv23dS3Ddkp`
};
export default ___CSS_LOADER_EXPORT___;
