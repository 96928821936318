// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rfYN6lI2rqNcft20qljg{margin-top:12px}.wH65AxgxBtyOxwH0EvmX{display:flex;justify-content:center;width:100%}.wmjKKo6wS9Q6NrvQKlFA{padding-bottom:9px;width:100%;text-align:left}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/CollectDisability/CollectDisability.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,UAAA,CAGF,sBACE,kBAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".collectDisabilityContainer {\n  margin-top: 12px;\n}\n\n.optionContainer {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.questionLabel {\n  padding-bottom: 9px;\n  width: 100%;\n  text-align: left;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectDisabilityContainer": `rfYN6lI2rqNcft20qljg`,
	"optionContainer": `wH65AxgxBtyOxwH0EvmX`,
	"questionLabel": `wmjKKo6wS9Q6NrvQKlFA`
};
export default ___CSS_LOADER_EXPORT___;
