// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.urhY7qrJRCFhrW7qeS0m{border-top:1px solid #d3d3d3;display:flex;padding:6px 0 12px 0;box-sizing:content-box;width:100%}.hKnS4Zrdd2s9ZbIuVyhZ{width:100vw}.OluFrfDau9q6_tOVClVw{width:960px}.iV7jx7TCWfnklj4RKSaT{padding:0 8px 42px 8px}@media only screen and (max-width: 1080px){.OluFrfDau9q6_tOVClVw{width:90%}}`, "",{"version":3,"sources":["webpack://./src/app/components/CustomCKEditor/CustomCKEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CACA,YAAA,CACA,oBAAA,CACA,sBAAA,CACA,UAAA,CAGF,sBACE,WAAA,CAGF,sBACE,WAAA,CAGF,sBACE,sBAAA,CAGF,2CACE,sBACE,SAAA,CAAA","sourcesContent":[".navigation {\n  border-top: 1px solid lightgray;\n  display: flex;\n  padding: 6px 0 12px 0;\n  box-sizing: content-box;\n  width: 100%;\n}\n\n.tabs {\n  width: 100vw;\n}\n\n.ckeditorWrapper {\n  width: 960px;\n}\n\n.disabledDiv {\n  padding: 0 8px 42px 8px;\n}\n\n@media only screen and (max-width: 1080px) {\n  .ckeditorWrapper {\n    width: 90%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `urhY7qrJRCFhrW7qeS0m`,
	"tabs": `hKnS4Zrdd2s9ZbIuVyhZ`,
	"ckeditorWrapper": `OluFrfDau9q6_tOVClVw`,
	"disabledDiv": `iV7jx7TCWfnklj4RKSaT`
};
export default ___CSS_LOADER_EXPORT___;
