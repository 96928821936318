// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Km55x6oXeko6NXvhmTHw{overflow-y:scroll;height:420px;display:flex;flex-flow:column;align-items:center}.ThnIg1dmkSbfXhGhYo2c{position:absolute;width:95%;background-color:#fff;display:flex;justify-content:center;align-items:center;padding:.5em}.Jlz13m6KAKz00cvyTHwT{display:flex;min-width:100%}.Jlz13m6KAKz00cvyTHwT :hover{background-color:rgba(253,129,160,.3)}.LZlkTGDCICy3egR9vrBd{cursor:pointer;width:90%;min-width:90%}.TI5KvwxN53C9w0kCJ0dh{cursor:default;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/EligibilityConfiguration/components/IAPTConfigurator/components/CCGExplainer/CCGExplainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,YAAA,CACA,gBAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,cAAA,CACA,6BACE,qCAAA,CAIJ,sBACE,cAAA,CACA,SAAA,CACA,aAAA,CAGF,sBACE,cAAA,CACA,mBAAA","sourcesContent":[".ccgCodesWrapper {\n  overflow-y: scroll;\n  height: 420px;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.ccgCodesSearchInputContainer {\n  position: absolute;\n  width: 95%;\n  background-color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0.5em;\n}\n\n.ccgCodesContainer {\n  display: flex;\n  min-width: 100%;\n  :hover {\n    background-color: rgba(253, 129, 160, 0.3);\n  }\n}\n\n.ccgKeyLabel {\n  cursor: pointer;\n  width: 90%;\n  min-width: 90%;\n}\n\n.ccgCodeLabel {\n  cursor: default;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ccgCodesWrapper": `Km55x6oXeko6NXvhmTHw`,
	"ccgCodesSearchInputContainer": `ThnIg1dmkSbfXhGhYo2c`,
	"ccgCodesContainer": `Jlz13m6KAKz00cvyTHwT`,
	"ccgKeyLabel": `LZlkTGDCICy3egR9vrBd`,
	"ccgCodeLabel": `TI5KvwxN53C9w0kCJ0dh`
};
export default ___CSS_LOADER_EXPORT___;
