// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dqBNYEiW1q3gE_XZjDjP{position:relative}.LXRbn7qQUxAUWEwFCnop{cursor:default;margin-left:36px;position:relative;width:fit-content;max-width:260px;padding:6px 14px;border-radius:0 22px 22px 0;border-color:rgba(7,34,48,.1);background-color:#fff;white-space:pre-wrap;border-bottom-left-radius:22px;border-bottom:3px solid rgba(7,34,48,.1);text-align:left}.uEYCvxK_jWmwBWeeWcYN{position:absolute;left:0;top:0;height:36px}`, "",{"version":3,"sources":["webpack://./src/app/components/BotPlayground/components/BotBubble/BotBubble.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,2BAAA,CACA,6BAAA,CACA,qBAAA,CACA,oBAAA,CACA,8BAAA,CACA,wCAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA","sourcesContent":[".botBubbleContainer {\n  position: relative;\n}\n\n.botBubbleMessage {\n  cursor: default;\n  margin-left: 36px;\n  position: relative;\n  width: fit-content;\n  max-width: 260px;\n  padding: 6px 14px;\n  border-radius: 0 22px 22px 0;\n  border-color: rgba(7, 34, 48, 0.1);\n  background-color: #ffffff;\n  white-space: pre-wrap;\n  border-bottom-left-radius: 22px;\n  border-bottom: 3px solid rgba(7, 34, 48, 0.1);\n  text-align: left;\n}\n\n.botLogo {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botBubbleContainer": `dqBNYEiW1q3gE_XZjDjP`,
	"botBubbleMessage": `LXRbn7qQUxAUWEwFCnop`,
	"botLogo": `uEYCvxK_jWmwBWeeWcYN`
};
export default ___CSS_LOADER_EXPORT___;
