import { getConnectedEdges, useStore } from "react-flow-renderer"
import { useCallback } from "react"

export default function useDeleteNode(id: string) {
  const store = useStore()
  return useCallback(() => {
    const node = store.nodeInternals.get(id)
    const edges = getConnectedEdges([node!], store.edges)
    store.onNodesDelete?.([node!])
    store.onEdgesDelete?.(edges)
  }, [id, store])
}
