import { styled } from "@stitches/react"
import * as SeparatorPrimitive from "@radix-ui/react-separator"
import { violet } from "@radix-ui/colors"

export const Box = styled("div", {})
export const Flex = styled("div", { display: "flex" })
export const Row = styled("div", { display: "flex", flexDirection: "row" })
export const Column = styled("div", { display: "flex", flexDirection: "column" })

export const Separator = styled(SeparatorPrimitive.Root, {
  backgroundColor: violet.violet6,
  "&[data-orientation=horizontal]": { height: 1, width: "100%" },
  "&[data-orientation=vertical]": { height: "100%", width: 1 }
})
