// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qUT9GhOjHBILEt9CugMg{display:flex;flex-flow:column}.Fvh85aSc4GEhEWGnBqqK{display:flex;align-items:center;justify-content:center;flex-flow:column}.RbHKOW2yd8FzsxDYUQuw{flex-direction:column}.jIojAKCbIW2eFkV8y6eB{display:flex;flex-flow:row;align-items:center;margin:0 12px}.NDi6lbHmQB5CSpCxXfNA{color:red;width:240px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/app/components/HighLevelFlow/HighLevelFlow.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,kBAAA,CACA,aAAA,CAGF,sBACE,SAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".buttonContainer {\n  display: flex;\n  flex-flow: column;\n}\n\n.diagramDraggableContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-flow: column;\n}\n\n.draggableContainer {\n  flex-direction: column;\n}\n\n.draggableButtonContainer {\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n  margin: 0 12px;\n}\n\n.errors {\n  color: red;\n  width: 240px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `qUT9GhOjHBILEt9CugMg`,
	"diagramDraggableContainer": `Fvh85aSc4GEhEWGnBqqK`,
	"draggableContainer": `RbHKOW2yd8FzsxDYUQuw`,
	"draggableButtonContainer": `jIojAKCbIW2eFkV8y6eB`,
	"errors": `NDi6lbHmQB5CSpCxXfNA`
};
export default ___CSS_LOADER_EXPORT___;
