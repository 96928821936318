// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P_xNH16mHJdWbWZrU0Uh{display:flex;flex-flow:column;justify-content:center;align-items:center;padding:16px 0;width:calc(100% - 16px);max-width:960px;min-width:320px}`, "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/Configuration.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,uBAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".configurationWrapper {\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  padding: 16px 0;\n  width: calc(100% - 16px);\n  max-width: 960px;\n  min-width: 320px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configurationWrapper": `P_xNH16mHJdWbWZrU0Uh`
};
export default ___CSS_LOADER_EXPORT___;
