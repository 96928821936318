// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tQ09jczrpiooLQjwlCJv{padding:2px !important}.tQ09jczrpiooLQjwlCJv svg{height:24px}.OW2F1kcTGu2wVb6JnwA4{margin-left:6px !important;padding:4px !important}.OW2F1kcTGu2wVb6JnwA4 svg{height:24px}.OW2F1kcTGu2wVb6JnwA4 span{height:32px;width:32px}.dh2lA0lKNDTCJB78QHuX{display:flex;flex-flow:row;align-items:center}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/MessageList/MessageList.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,0BACE,WAAA,CAIJ,sBACE,0BAAA,CACA,sBAAA,CACA,0BACE,WAAA,CAEF,2BACE,WAAA,CACA,UAAA,CAIJ,sBACE,YAAA,CACA,aAAA,CACA,kBAAA","sourcesContent":[".addButton {\n  padding: 2px !important;\n  & svg {\n    height: 24px;\n  }\n}\n\n.deleteButton {\n  margin-left: 6px !important;\n  padding: 4px !important;\n  & svg {\n    height: 24px;\n  }\n  & span {\n    height: 32px;\n    width: 32px;\n  }\n}\n\n.messageRow {\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": `tQ09jczrpiooLQjwlCJv`,
	"deleteButton": `OW2F1kcTGu2wVb6JnwA4`,
	"messageRow": `dh2lA0lKNDTCJB78QHuX`
};
export default ___CSS_LOADER_EXPORT___;
