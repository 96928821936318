import self from "./bigBotConfiguration"
import { API_KEY, BACKEND_URL } from "../../config/config"
import NetworkError from "../../models/NetworkError"
import delay from "../../utils/delay"
import { BigBotConfiguration, IDashboardDoc } from "@limbic/types"
import invariant from "../../utils/invariant"
import Logger from "../../utils/Logger"
import { getPresignedURL } from "./service"
import formatUnicorn from "../../utils/formatUnicorn"

const TOTAL_RETRIES = 3
const CONFIGURATION_URL = `${BACKEND_URL}/v1/dashboard/{serviceApiKey}/bigbot`

const method = "POST"
const headers = new Headers()
headers.set("x-api-key", API_KEY)
headers.set("Content-Type", "application/json")

export default async function updateBigBotConfiguration(
  serviceApiKey: string,
  config: BigBotConfiguration,
  image?: File,
  retry = 0
): Promise<IDashboardDoc | undefined> {
  invariant(serviceApiKey, "Cannot update configuration without a serviceApiKey")

  const payload = { ...config }

  let uploadUrl
  let uploadHeaders
  if (image && config.sidebarIAPTIcon) {
    const fileType = `iapt-icon.${image?.type.split("/")[1]}` as
      | "iapt-icon.jpeg"
      | "iapt-icon.jpg"
      | "iapt-icon.png"
    uploadUrl = await getPresignedURL(serviceApiKey, fileType)
    uploadHeaders = new Headers()
    uploadHeaders.set("Content-Type", image?.type)
    const uploadLogo = await fetch(uploadUrl, {
      method: "PUT",
      headers: uploadHeaders,
      body: image
    })
    payload.sidebarIAPTIcon = uploadLogo.url.split("?")[0]
  }

  const URL = formatUnicorn(CONFIGURATION_URL, { serviceApiKey })
  const body = JSON.stringify(payload)

  try {
    const result = await fetch(URL, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "updateBigBotConfiguration failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`updateBigBotConfiguration body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("updateBigBotConfiguration retry")
      await delay(1)
      return await self(serviceApiKey, config, image, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
