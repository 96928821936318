import React from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import styles from "./Questionnaire.module.scss"
import {
  ChatFlowsEnum,
  AssessmentType,
  QuestionnaireDialogue,
  DEFAULT_QUESTIONNAIRES
} from "@limbic/types"
import { useNodeEditorStore } from "../../../../../../../../context/rootStoreContext"
import { observer } from "mobx-react"

interface QuestionnaireProps {
  assessmentType: AssessmentType
  options: QuestionnaireDialogue[]
  onSelect: (assessmentType: AssessmentType, questionnaire: QuestionnaireDialogue) => void
}

const Questionnaire = ({ assessmentType, options, onSelect }: QuestionnaireProps): JSX.Element => {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const currentSettings = chatFlowSettings[ChatFlowsEnum.ASSESSMENT_CUSTOMISABLE_ADSM]
  const selectedQuestionnaire = currentSettings?.customQuestionnaires?.[assessmentType]

  const handleChange = event => {
    const newValue = event.target.value
    onSelect(assessmentType, newValue)
  }

  return (
    <div className={styles.questionnaire_container}>
      <div>{assessmentType}:</div>
      <div>
        <Select
          id="select-assessment-input"
          color="primary"
          variant="standard"
          className={styles.select}
          onChange={handleChange}
          value={selectedQuestionnaire ?? DEFAULT_QUESTIONNAIRES[assessmentType]}
          disabled={options.length <= 1}>
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default observer(Questionnaire)
