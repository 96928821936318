// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GL30nvd5MuOqyNM2q9NC{color:red;border:1px solid red;margin-left:8px;padding:2px !important}.GL30nvd5MuOqyNM2q9NC:hover{background-color:rgba(255,0,0,.2)}.GL30nvd5MuOqyNM2q9NC svg{width:16px;height:16px}`, "",{"version":3,"sources":["webpack://./src/app/views/BasicConfiguration/components/TranslationsConfiguration/components/CustomCells/CellWithButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,oBAAA,CACA,eAAA,CACA,sBAAA,CACA,4BACE,iCAAA,CAEF,0BACE,UAAA,CACA,WAAA","sourcesContent":[".deleteButton {\n  color: red;\n  border: 1px solid red;\n  margin-left: 8px;\n  padding: 2px !important;\n  &:hover {\n    background-color: rgba(255, 0, 0, 0.2);\n  }\n  & svg {\n    width: 16px;\n    height: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteButton": `GL30nvd5MuOqyNM2q9NC`
};
export default ___CSS_LOADER_EXPORT___;
