import { MouseEvent } from "react"
import { useFlowStore, useNodeEditorStore } from "../../../../../context/rootStoreContext"
import { NodeProps, Position } from "react-flow-renderer"
import classNames from "classnames"
import { CustomNode } from "../CustomNode"
import NodeSettings from "../NodeSettings"
import useDeleteNode from "../../../../../hooks/useDeleteNode"
import { styled } from "@stitches/react"
import { EditorType } from "../../../models/NodeEditors"
import ChatFlowPaths from "./ChatFlowPaths"
import { capitaliseFirst } from "../../../../../../utils/string"
import { ChatFlowsEnum, IDashboardNode } from "@limbic/types"

interface IProps extends NodeProps {
  value?: string
  editable?: boolean
  onChange?: (value?: string) => void
  onEditingChange?: (editing: boolean) => void
}

const sourceStyle = ({ position, style }) => ({ ...style, ...styleMap[position] })

export default function ChatFlowNode(props: IProps) {
  const { id, data, selected, dragging } = props
  const onDelete = useDeleteNode(id)
  const nodeEditorStore = useNodeEditorStore()
  const flowStore = useFlowStore()

  const onDoubleClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    nodeEditorStore.setEditorOpen(EditorType.CHAT_FLOW, id)
  }

  const label = getNodeLabel(data.label ?? "Default Chat Flow", flowStore.getNode(id))

  return (
    <CustomNode.Container
      id={id}
      dynamicHandles
      dragging={dragging}
      sourceHandleStyle={sourceStyle}
      targetHandleStyle={sourceStyle}>
      <Container
        choices={data.choices && data.choices.length > 10}
        className={classNames("react-flow__node-default", { selected })}>
        <CustomNode.CloneButton onClick={() => data.onDuplicate(id)} title="Clone">
          <span style={{ fontSize: "12px", marginTop: "-2px" }}>+</span>
        </CustomNode.CloneButton>
        <CustomNode.NodeId chatFlowNode>{id}</CustomNode.NodeId>
        <CustomNode.Label>{label}</CustomNode.Label>
        <CustomNode.Container id="container"></CustomNode.Container>
        <ChatFlowPaths type={data.chatFlow} nodeID={id} />
      </Container>
      <NodeSettings open={props.selected && !props.dragging} onDelete={onDelete} />
      <DoubleClickOverlay onDoubleClick={onDoubleClick} />
    </CustomNode.Container>
  )
}

const DoubleClickOverlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

const horizontalHandleStyle = {
  width: 20,
  height: 5,
  borderRadius: 0
}

const verticalHandleStyle = {
  width: 5,
  height: 20,
  borderRadius: 0
}

const styleMap = {
  [Position.Left]: verticalHandleStyle,
  [Position.Right]: verticalHandleStyle,
  [Position.Top]: horizontalHandleStyle,
  [Position.Bottom]: horizontalHandleStyle
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
  width: "auto",
  maxWidth: "35vw",
  backgroundColor: "#bfbfbf !important",
  variants: {
    choices: { true: { maxWidth: "60vw" } }
  }
})

function getNodeLabel(defaultLabel: string, node?: IDashboardNode): string {
  const { chatFlow, chatFlowSettings } = node?.settings ?? {}

  if (chatFlow === ChatFlowsEnum.QUESTIONNAIRES) {
    const chatFlowLabel = capitaliseFirst(chatFlow) as string
    const qLabel = chatFlowSettings?.questionnaires?.questionnaire?.toUpperCase()
    return qLabel ? `${chatFlowLabel}: ${qLabel}` : chatFlowLabel
  }
  return defaultLabel
}
