// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AK0GX3iMsntcNYrorpnU{width:100%;margin-bottom:16px}.W6PPo62rS6l0ghBMKSHI{margin-bottom:1em;color:rgba(0,0,0,.7)}.HWQkSmYj545DISjtam3W{margin-bottom:1em}.ggKyxSb2vH28jBkMJ1FS{margin:2px !important}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/GoodbyeRecap/components/ConditionsBuilder/ConditionsBuilder.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,oBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,qBAAA","sourcesContent":[".autocompleteOptions {\n  width: 100%;\n  margin-bottom: 16px;\n}\n\n.goodbyeRecapTitle {\n  margin-bottom: 1em;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.conditionsAccordion {\n  margin-bottom: 1em;\n}\n\n.recapConditionChip {\n  margin: 2px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocompleteOptions": `AK0GX3iMsntcNYrorpnU`,
	"goodbyeRecapTitle": `W6PPo62rS6l0ghBMKSHI`,
	"conditionsAccordion": `HWQkSmYj545DISjtam3W`,
	"recapConditionChip": `ggKyxSb2vH28jBkMJ1FS`
};
export default ___CSS_LOADER_EXPORT___;
