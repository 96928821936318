import { Position, HandleProps as RFR_HandleProps, ReactFlowStore } from "react-flow-renderer"
import { CSSProperties } from "@stitches/react"

export type HandleProps = RFR_HandleProps & { hidden?: boolean; style: CSSProperties }
export type HandleStyleFn = (params: {
  id: string
  type: "source" | "target"
  position: Position
  style: CSSProperties
}) => CSSProperties

const positions = [Position.Left, Position.Right, Position.Top, Position.Bottom]

export function getDynamicHandles(
  nodeId: string,
  store: ReactFlowStore,
  sourceStyle: CSSProperties | HandleStyleFn = SOURCE_STYLE,
  targetStyle: CSSProperties | HandleStyleFn = TARGET_STYLE
) {
  const isConnecting = !!store.connectionNodeId
  const isConnectingNode = store.connectionNodeId === nodeId
  const handleId = store.connectionHandleId
  const result: HandleProps[] = []
  for (let i = 0, { length } = positions; i < length; i++) {
    const position = positions[i]
    const tID = `t-${position}`
    const tStyle =
      typeof targetStyle === "function" //
        ? targetStyle({ id: tID, type: "target", position, style: TARGET_STYLE })
        : targetStyle
    result.push(createTarget(tID, { position, style: tStyle }))

    const sID = `s-${position}`
    const sHidden = isConnectingNode ? handleId !== sID : isConnecting
    const sStyle =
      typeof sourceStyle === "function" //
        ? sourceStyle({ id: sID, type: "source", position, style: SOURCE_STYLE })
        : sourceStyle
    result.push(createSource(sID, { position, style: sStyle, hidden: sHidden }))
  }

  return result
}

const createTarget = (id: string, props: Omit<HandleProps, "id" | "type">): HandleProps => ({
  ...props,
  id,
  type: "target"
})
const createSource = (id: string, props: Omit<HandleProps, "id" | "type">): HandleProps => ({
  ...props,
  id,
  type: "source"
})

const TARGET_STYLE: CSSProperties = { backgroundColor: "black", borderColor: "black" }
const SOURCE_STYLE: CSSProperties = { backgroundColor: "white", borderColor: "black" }
